import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { Box, keyframes } from '@mui/system';
import { PRIMARY } from '../../styles/AOCTheme';

const StyledBox = styled(Box)(() => ({
	width: 7,
	height: 7,
	margin: 2,
	display: 'inline-block',
}));

const leaderboardAnimation = keyframes`
  	from {
    	background-color: ${PRIMARY};
  	}
  	to {
    	background-color: red;
  	}
`;

const Loading: React.FC = () => {
	return <div>
		<Typography display='inline' variant='body2'>Nalaganje</Typography>
		<div style={{ display: 'inline-block' }}>
			<StyledBox component='div' sx={{ animation: `${leaderboardAnimation} ${1}s linear infinite`, animationDelay: `${0}s` }}></StyledBox>
			<StyledBox component='div' sx={{ animation: `${leaderboardAnimation} ${2}s linear infinite`, animationDelay: `${0.3}s` }}></StyledBox>
			<StyledBox component='div' sx={{ animation: `${leaderboardAnimation} ${3}s linear infinite`, animationDelay: `${0.8}s` }}></StyledBox>
		</div>
	</div>;
};

export default Loading;
