export const encodeSolution = (input: string, onError: (msg: string) => void): Uint8Array | null => {
	const inputSplit = input.split('\n').map((row) => row.trim());
	const BYTES_PER_COMMAND = 6;
	const buffer = new Uint8Array(inputSplit.length * BYTES_PER_COMMAND);
	let bufferIdx = 0;
	const parseRegex = /^(0|(?:[1-9][0-9]{0,3})) (0|(?:[1-9][0-9]{0,3})) (0|(?:[1-9][0-9]{0,3})) (0|(?:[1-9][0-9]{0,3})) ([BWI])$/;

	for (const line of inputSplit) {
		const match = line.match(parseRegex);

		if (!match) {
			onError(`Neveljavna vrstica: ${line}`);

			return null;
		}
		const [, xStr, yStr, wStr, hStr, op] = match;
		const x = +xStr;
		const y = +yStr;
		const w = +wStr;
		const h = +hStr;
		const opNum = { W: 0, B: 1, I: 2 }[op]!; // 2 bits at most.

		if (x >= 1024 || y >= 1024 || w > 1024 || h > 1024 || w === 0 || h === 0) {
			onError(`Vrstica vsebuje neveljavne parametre: ${line}`);

			return null;
		}

		// 1024-1 is 10 bits! thus we push for values [0-1023]
		buffer[bufferIdx + 0] = x & 0b00_1111_1111;
		buffer[bufferIdx + 1] = (x >>> 8) & 0b11;

		buffer[bufferIdx + 1] |= (y & 0b00_0011_1111) << 2;
		buffer[bufferIdx + 2] = (y >>> 6) & 0b1111;

		buffer[bufferIdx + 2] |= ((w - 1) & 0b00_0000_1111) << 4;
		buffer[bufferIdx + 3] = ((w - 1) >>> 4) & 0b11_1111;

		buffer[bufferIdx + 3] |= ((h - 1) & 0b00_0000_0011) << 6;
		buffer[bufferIdx + 4] = ((h - 1) >>> 2) & 0b1111_1111;

		buffer[bufferIdx + 5] = opNum & 0b11;

		bufferIdx += 6;
	}

	return buffer;
};
