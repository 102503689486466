const mapFirebaseAuthErrorCodesToDescription = (errorCode: string) => {
	switch (errorCode) {
		case 'auth/popup-blocked':
			return 'Vaš brskalnik blokira pojavna okna, prosimo vključite jih, če se želite prijaviti.';
		default:
			return 'Oops, nekaj je šlo narobe.';
	}
};

export default mapFirebaseAuthErrorCodesToDescription;
