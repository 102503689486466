import { SNOWFLAKE_COLOR } from 'styles/AOCTheme';
import * as THREE from 'three';

export type SnowFlake = {
	flakeMesh: THREE.Mesh;
	x: number;
	y: number;
	speedRng: number;
	dirRng: number;
	vx: number;
	vy: number;
};

export const produceFlakes = (width: number, boostFactor: number = 1): SnowFlake[] => {
	const geometry = new THREE.PlaneGeometry(1, 1);
	const yOffset = 150;
	const xDist = 25;
	const count = Math.ceil(width / xDist) * boostFactor;

	return new Array(count).fill(null).map((_, i) => {
		const flakeMesh = new THREE.Mesh(geometry);

		// Three.js type definitions were severely wrong with this one.
		(flakeMesh.material as unknown as { color: { set(color: string): void } }).color.set(SNOWFLAKE_COLOR);

		return {
			flakeMesh,
			x: (i * xDist) % width,
			y: -yOffset * Math.random(),
			speedRng: Math.random(),
			dirRng: Math.random() * 2 - 1,
			vx: 0,
			vy: 0,
		};
	});
};
