import { createImageContainer } from './image-container-factory';
import * as THREE from 'three';

export const createAnimatorAsync = async (eventId: string, taskId: string, partNumber: number, canvasRef: React.RefObject<HTMLCanvasElement>) => {
	const imageContainer = createImageContainer(eventId, taskId, partNumber);

	const scene = new THREE.Scene();
	const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current!, alpha: true });

	const planeDim = 200;
	const geometry = new THREE.PlaneGeometry(planeDim, planeDim);

	const worldWidth = 1024;
	const worldHeight = 256;

	const leftBorder = -1024 / 2;
	const rightBorder = 1024 / 2;

	const leftPlaneSpawn = leftBorder - planeDim / 2;
	const rightPlaneSpawn = rightBorder + planeDim / 2;

	const camera = new THREE.OrthographicCamera(worldWidth / -2, worldWidth / 2, worldHeight / 2, worldHeight / -2, 1, 10);

	camera.position.z = 10;
    canvasRef.current!.style.width = '100%';
    const maxNumberOfPlanes = imageContainer.onScreenImages;

    await imageContainer.init();

    const createPlanes = () => {
    	const planePadding = (worldWidth - (maxNumberOfPlanes - 1) * planeDim) / maxNumberOfPlanes;
    	const planes = new Array(maxNumberOfPlanes).fill(null).map(() => new THREE.Mesh(geometry, imageContainer.pullNext()));

    	planes[0].position.x = leftPlaneSpawn;
    	for (let i = 0; i < planes.length - 1; i++) {
    		const p = planes[i + 1];
    		const c = planes[i];

    		p.position.x = c.position.x - (planeDim + planePadding);
    	}

    	return planes;
    };

    const planes = createPlanes();

    for (const plane of planes) {
    	scene.add(plane);
    }
    renderer.setSize(worldWidth, worldHeight, false);

    camera.updateMatrix();
    camera.updateMatrixWorld();
    camera.updateProjectionMatrix();

    const onRender = () => {
    	for (const plane of planes) {
    		if (plane.position.x > rightPlaneSpawn) {
    			plane.position.x = leftPlaneSpawn;
    			plane.material.uniforms.u_matrix.value = imageContainer.pullNextDataOnly();
    		}
    		plane.position.x += 1;
    		plane.position.y = Math.sin(plane.position.x / 30) * 10;
    	}
    	renderer.render(scene, camera);
    };

    return { onRender, imageContainer };
};
