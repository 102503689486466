export const enum Season {
	Christmas = 0,
	Halloween = 1,
}

export interface SeasonInfo {
	zenModeText: string;
	zenModeIcon: string;
	animationModeText: string;
	animationModeIcon: string;
	letterColorSequence: string[];
	editProfileText: string;
	loginInfoAdditionalText: string;
	loginText: string;
	logoutText: string;
	logoutIcon: string;
	mainTitle: string;
}

export const getCurrentSeason = (): Season => {
	const currentDate = new Date();
	const currentMonth = currentDate.getMonth();
	const currentDay = currentDate.getDate();

	// Return Halloween two weeks before 31. in October and first week of November.
	if ((currentMonth === 9 && currentDay >= 17) ||
		(currentMonth === 10 && currentDay <= 7)
	) {
		return Season.Halloween;
	}

	return Season.Christmas;
};

export const getCurrentSeasonInfo = (): SeasonInfo => {
	const defaultInfo: SeasonInfo = {
		zenModeText: 'Počutim se božično!',
		zenModeIcon: '🎄',
		animationModeText: 'Želim se zbrati',
		animationModeIcon: '🧹',
		letterColorSequence: ['#970604', '#ebaa6a', '#ED3434', '#ce8852', '#00B341', '#DDC898'],
		editProfileText: 'Si želiš urediti svoj profil?',
		loginText: 'Prijava',
		loginInfoAdditionalText: 'in postani Božičkov pomočnik.',
		logoutText: 'Zapusti polarni kontinent',
		logoutIcon: '🚪',
		mainTitle: 'Advent programiranja',
	};

	switch (getCurrentSeason()) {
		case Season.Halloween:
			return {
				...defaultInfo,
				zenModeText: 'Ničesar se ne bojim!',
				zenModeIcon: '👻',
				letterColorSequence: ['#FF6F00', '#D35400', '#FFA500', '#FF8C42', '#CC5500'],
				loginInfoAdditionalText: 'in premagaj strah pred nočjo čarovnic.',
			};
		case Season.Christmas:
		default:
			return defaultInfo;
	}
};
