export const getLeaderboardTitle = (key: string) => {
	switch (key) {
		case 'bestOptimizer':
			return '⚙️Lestvica optimizatorjev⚙️';
		case 'fastestRelative':
			return '🔥Lestvica vročega stola🔥';
		case 'fastestAbsolute':
			return '🚀Brzinkova lestvica🚀';
		case 'bestPerfectionist':
			return '👌🏻Lestvica perfektnih prstkov👌🏻';
		default:
			return null;
	}
};
