import React from 'react';

export function SpecialBottomComponent(props: { taskName: string | null; adventOfDesign: { hasGalleryAvailable: boolean; canvasRef: React.RefObject<HTMLCanvasElement> } }) {
	switch (props.taskName) {
		case 'AdventOfDesign': {
			return <div style={props.adventOfDesign.hasGalleryAvailable ? {} : { display: 'none' }}>
				<hr /><h2 style={{ textAlign: 'center' }}><em>Polarna Razstavna Galerija</em> - <i>Pixli, ki so šli po svoje</i></h2>
				<canvas ref={props.adventOfDesign.canvasRef}></canvas>
			</div>;
		}
		default: return null;
	}
}
