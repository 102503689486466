import { Link as MUILink, List, ListItem, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { fetchEventsPreview, selectEventsPreview } from 'state/events/eventsSlice';
import { COMPLETED_SNOWFLAKE_COLOR, WHITE } from 'styles/AOCTheme';

const EventsList = () => {
	const events = useAppSelector(selectEventsPreview);
	const eventsLoaded = Boolean(events);
	const [getDelayedLoadingState, setDelayedLoadingState] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchEventsPreview());
		// prevent blinking from loading to showing the data. Only show loader if there has been no data for 250ms
		setTimeout(() => setDelayedLoadingState(true), 250);
	}, [dispatch]);

	const eventWinnersTooltips: Record<string, JSX.Element | null> = {};
	const winnersHeader = <><b>Najboljši Božičkovi pomagači</b><hr></hr></>;
	const rankToIconLookup = ['🥇', '🥈', '🥉'];

	if (events?.length) {
		for (const event of events.filter((event) => event.bestSolvers?.length)) {
			eventWinnersTooltips[event.id] = <p style={{ fontSize: 15 }}>
				{winnersHeader} {event.bestSolvers!.map((b) => `${b.rank}. ${rankToIconLookup[b.rank - 1]} ${b.displayName}`).map((v) => <span key={v}>{v} <br /></span>)}
			</p>;
		}
	}

	return (
		<div style={{ marginLeft: '8px' }}>
			{getDelayedLoadingState && !eventsLoaded && <div style={{ marginLeft: '8px', paddingTop: 25 }}><Loading /></div> || <></>}
			<List style={{ fontSize: '25px', marginTop: 24, fontFamily: '\'Press Start 2P\'' }}>
				{events?.map((event, i) => (
					<ListItem key={event.id} style={{ paddingTop: 25, fontSize: i == 0 && !event.finished ? 30 : 20 }}>
						<Tooltip arrow PopperProps={{
							modifiers: [
								{
									name: 'offset',
									options: {
										offset: [0, 20],
									},
								},
							],
						}}
						open={!eventWinnersTooltips[event.id] ? false : undefined}
						followCursor
						title={eventWinnersTooltips[event.id] ?? <></>}>
							<span>
								<MUILink
									className={i == 0 && !event.finished ? 'mainEvent' : undefined}
									color={i == 0 && !event.finished ? COMPLETED_SNOWFLAKE_COLOR : WHITE}
									component={Link}
									key={event.id}
									to={`/events/${event.id}/tasks-preview/`}>
									{`* ${event.name}`} {event.finished
										? <sub>(končan)</sub>
										: event.endsOnDate
											? <span style={{ fontSize: 11 }}><br></br>lestvica se zamrzne {new Date(event.endsOnDate).toLocaleString('sl-SI').replace(',', ' ob ')}</span>
											: <></>}
								</MUILink>
							</span>
						</Tooltip>
					</ListItem>
				))}
			</List>
		</div >
	);
};

export default EventsList;
