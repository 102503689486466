import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import AOCError from 'API/AOCError';
import { getEvent, getEventsPreview } from 'API/events';
import { RootState } from 'state/store';

interface EventInfo { message: string; title: string };

export type Event = {
	id: string;
	name: string;
	finished: boolean;
	startCollectingTShirtsDate: string;
	endCollectingTShirtsDate: string;
	rewardsMessage: string;
	endsOnDate?: string;
	bestSolvers?: { rank: number; displayName: string }[];
};

interface EventsState {
	eventsPreview: Event[] | undefined;
	events: { [eventId: string]: Event | undefined };
};

const initialState: EventsState = {
	eventsPreview: undefined,
	events: {},
};

export const fetchEventsPreview = createAsyncThunk<Event[], void, { rejectValue: EventInfo }>(
	'events/fetchEventsPreview',
	async (_, thunkAPI) => {
		try {
			const events: Event[] = await getEventsPreview();

			return events;
		} catch (e) {
			if (e instanceof AOCError) {
				return thunkAPI.rejectWithValue({ title: 'Napaka', message: e.message });
			}

			return thunkAPI.rejectWithValue({ title: 'Napaka', message: 'Oops, nekaj je šlo narobe :(.' });
		}
	},
);

export const fetchEvent = createAsyncThunk<Event, { eventId: string }, { rejectValue: EventInfo }>(
	'events/fetchEvent',
	async ({ eventId }, thunkAPI) => {
		try {
			const event: Event = await getEvent(eventId);

			return event;
		} catch (e) {
			if (e instanceof AOCError) {
				return thunkAPI.rejectWithValue({ title: 'Napaka', message: e.message });
			}

			return thunkAPI.rejectWithValue({ title: 'Napaka', message: 'Oops, nekaj je šlo narobe :(.' });
		}
	},
);

export const selectEventsPreview = createSelector(
	(state: RootState) => state.events.eventsPreview,
	(eventsPreview) => eventsPreview,
);

export const selectEvent = createSelector(
	[
		(state: RootState) => state.events.events,
		(state: RootState, eventId: string) => eventId,
	],
	(events, eventId) => events[eventId],
);

const eventsSlice = createSlice({
	name: 'events',
	initialState,
	extraReducers: (builder) => {
		builder.addCase(fetchEventsPreview.fulfilled, (state, action) => {
			state.eventsPreview = action.payload;
		});
		builder.addCase(fetchEvent.fulfilled, (state, action) => {
			state.events[action.payload.id] = action.payload;
		});
	},
	reducers: {},
});

export default eventsSlice.reducer;
