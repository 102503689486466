import { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { getCandyCaneFragmentShader, getCandyCaneVertexShader } from './shaders';
import { createBackgroundState } from './background-state';
import { SnowFlake, produceFlakes } from './snow-flake';
import { AnimationFrameFpsLock } from 'utils/animation-frame-fps-lock';
import { initializeSantaSleighs } from './santa-sleighs';

const zenModeWindowVariable = '$AOC_CANVAS_ZEN_MODE';

export const setBackgroundZenMode = (zenMode: boolean) => {
	(window as any)[zenModeWindowVariable] = zenMode;
};

export const getBackgroundZenMode = (): boolean => {
	return (window as any)[zenModeWindowVariable] ?? false;
};

const dirSqrt = (v: number) => Math.sign(v) * Math.sqrt(Math.abs(v));

const windowEventListenerCleanupFactory = <K extends keyof WindowEventMap>(type: K, callback: (this: Window, ev: WindowEventMap[K]) => void) => {
	window.addEventListener(type, callback);

	return () => window.removeEventListener(type, callback);
};

const SnowBackground = () => {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const backgroundAnimationState = useRef(createBackgroundState());

	useEffect(() => {
		const snowflakeProduceMaxMin = 25;
		let snowFlakes: SnowFlake[] = [];
		let nextSnowflakeMinProduce = snowflakeProduceMaxMin;

		function compileBackgroundMesh() {
			return new THREE.Mesh(
				new THREE.PlaneGeometry(2, 2, 1, 1),
				new THREE.ShaderMaterial({
					uniforms: backgroundAnimationState.current.uniforms,
					vertexShader: getCandyCaneVertexShader(),
					fragmentShader: getCandyCaneFragmentShader(),
				}));
		}

		function handleSnowflakesAnimation(canvas: HTMLCanvasElement) {
			const state = backgroundAnimationState.current;
			const scene = state.renderWorld?.scene;

			if (!scene) {
				return;
			}

			const minFlakeY = Math.min(...snowFlakes.map((flake) => flake.y));
			// flakes size
			const size = 8;
			// flakes speed constraints
			const minSpeed = 0.3;
			const maxSpeed = 2;
			// flakes distance from mouse pointer constraint
			const distT = 150;
			// flakes "features"
			const enableSnowWobble = true;
			const enableSnowRotate = true;

			if (!snowFlakes.length || minFlakeY > nextSnowflakeMinProduce || state.konamiCode.activated) {
				nextSnowflakeMinProduce = Math.random() * snowflakeProduceMaxMin;
				let flakeBoostFactor = 1;

				if (state.konamiCode.activated) {
					state.konamiCode.activated = false;
					flakeBoostFactor = 50;
				}
				const newFlakes = produceFlakes(canvas.width, flakeBoostFactor);

				scene.add(...newFlakes.map((f) => f.flakeMesh));
				snowFlakes.push(...newFlakes);
			}

			if (state.mouseForceCalculated) {
				state.mouseForceCalculated = false;
				// find flakes in range of mouse pointer, then apply scalar velocity based on distance from mouse pointer
				snowFlakes.forEach((sf) => {
					const dx = state.mouseX - sf.x;
					const dy = state.mouseY - sf.y;
					const dist = Math.sqrt(dx ** 2 + dy ** 2);
					const speed = Math.max(minSpeed, sf.speedRng * maxSpeed);
					const dSpeed = speed - minSpeed;
					const fSpeed = Math.max(dSpeed / (maxSpeed - minSpeed), 0.3);
					// slower (smaller) the flake, the less powerful should the force be (fSpeed *)
					// exponential scaling to have lesser impact the bigger the distance gets.
					const distForceFraction = fSpeed * Math.pow(1 - Math.min(dist / distT, 1), 2);
					const inDist = dist <= distT;

					if (!inDist) {
						return;
					}

					sf.vx += state.mouseForceX * distForceFraction;
					sf.vy += state.mouseForceY * distForceFraction;
				});
			}

			for (const snowflake of snowFlakes) {
				const speed = Math.max(minSpeed, snowflake.speedRng * maxSpeed);
				const dSpeed = speed - minSpeed;
				const fSpeed = dSpeed / (maxSpeed - minSpeed);
				const fSize = Math.max(2, fSpeed * size);
				const snowForce = {
					y: speed,
					x: snowflake.dirRng + (enableSnowWobble ? 1 : 0) * Math.sin(snowflake.y / 10 + snowflake.speedRng * 10) * 0.25,
				};

				// Map calculated values for view (PlaneGeometry three.js)
				snowflake.flakeMesh.position.x = snowflake.x;
				snowflake.flakeMesh.position.y = -snowflake.y;
				snowflake.flakeMesh.scale.x = fSize;
				snowflake.flakeMesh.scale.y = fSize;
				snowflake.flakeMesh.scale.z = fSize;
				if (enableSnowRotate) {
					snowflake.flakeMesh.rotation.z += -dirSqrt(snowflake.dirRng) * snowflake.speedRng * 0.1;
				}
				snowflake.y += snowForce.y + snowflake.vy;
				snowflake.x += snowForce.x + snowflake.vx;

				// damping of the velocity, then terminate at specific threshold so it doesn't damp infinitely.
				snowflake.vx *= 0.95;
				snowflake.vy *= 0.95;
				if (Math.abs(snowflake.vx) < 0.01) snowflake.vx = 0;
				if (Math.abs(snowflake.vy) < 0.01) snowflake.vy = 0;

			}
			const keepFlakeInSceneConditional = (flake: SnowFlake): boolean => flake.y < canvas.height + 25;

			scene.remove(...snowFlakes.filter((flake) => !keepFlakeInSceneConditional(flake)).map((f) => f.flakeMesh));
			snowFlakes = snowFlakes.filter((flake) => keepFlakeInSceneConditional(flake));
		}

		let santaResolvedState: Awaited<ReturnType<typeof initializeSantaSleighs>> | undefined;

		const mouseClickCleanup = windowEventListenerCleanupFactory('click', (e: MouseEvent) => {
			santaResolvedState?.onClick(e);
		});

		let flakesVisible = true;
		let santaVisible = true;
		const animation = new AnimationFrameFpsLock({
			maxFps: 60,
			onRender: () => {
				const zenMode = getBackgroundZenMode();

				const canvas = canvasRef.current;

				if (!canvas) {
					return;
				}

				const state = backgroundAnimationState.current;

				if (!state.renderWorld) {
					const renderer = new THREE.WebGLRenderer({ canvas });
					const camera = new THREE.OrthographicCamera();
					const scene = new THREE.Scene();

					camera.left = 0;
					camera.top = 0;
					camera.near = 0.1;
					camera.far = 1000;
					camera.position.z = 100;
					scene.add(compileBackgroundMesh());
					state.renderWorld = {
						camera,
						renderer,
						scene,
					};
					initializeSantaSleighs(scene, camera).then((result) => santaResolvedState = result);
				}

				const { renderer, camera, scene } = state.renderWorld;

				canvas.style.width = '100%';
				canvas.style.height = '100%';
				canvas.width = canvas.offsetWidth;
				canvas.height = canvas.offsetHeight;

				// force camera to match canvas dimensions, then update its aspect ratio and position
				camera.right = canvas.width;
				camera.bottom = -canvas.height;
				camera.updateProjectionMatrix();

				if (!zenMode) {
					state.uniforms.iGlobalTime.value += 1;
				}

				state.uniforms.height.value = canvas.height;

				renderer.setSize(canvas.width, canvas.height);

				if (!zenMode) {
					if (!flakesVisible) {
						snowFlakes.map((f) => f.flakeMesh.visible = true);
						flakesVisible = true;
					}

					if (santaResolvedState && !santaVisible) {
						santaResolvedState.setVisibility(true);
						santaVisible = true;
					}

					if (santaResolvedState) {

						santaResolvedState.onUpdate();
						const directionVector = santaResolvedState.getDirectionVector()!;
						const nosePosition = santaResolvedState.getRudolphNosePosition()!;
						const spotIntensity = santaResolvedState.getRudolphNoseIntensity()!;
						const rudolphScale = santaResolvedState.getRudolphScale()!;

						state.uniforms.spotDirection.value.set(directionVector.x, directionVector.y);
						state.uniforms.spotOrigin.value.set(nosePosition.x, canvas.height + nosePosition.y);
						state.uniforms.spotIntensity.value = spotIntensity;
						state.uniforms.reindeerScale.value = rudolphScale;
					}
					handleSnowflakesAnimation(canvas);
				} else {
					if (flakesVisible) {
						snowFlakes.map((f) => f.flakeMesh.visible = false);
						flakesVisible = false;
					}

					if (santaResolvedState && santaVisible) {
						santaResolvedState.setVisibility(false);
						state.uniforms.spotIntensity.value = 0;
						santaVisible = false;
					}
				}
				renderer.render(scene, camera);
			},
		});

		animation.start();

		return () => {
			animation.destroy();
			mouseClickCleanup();
		};
	}, [backgroundAnimationState]);

	useEffect(() => {
		const state = backgroundAnimationState.current;
		const mouseMoveCleanup = windowEventListenerCleanupFactory('mousemove', (e: MouseEvent) => {
			state.setMousePos(e.clientX, e.clientY);
		});
		const keyDownCleanup = windowEventListenerCleanupFactory('keydown', (e: KeyboardEvent) => {
			state.konamiCode.addKey(e.key.toLowerCase());
		});

		return () => {
			mouseMoveCleanup();
			keyDownCleanup();
		};

	}, [backgroundAnimationState]);

	return (<canvas ref={canvasRef}></canvas>);
};

export default SnowBackground;
