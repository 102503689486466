import { useContext, useState } from 'react';
import { Button } from '@mui/material';
import { UserContext } from 'components/App';
import { requestAPI } from 'API/requestApi';

const sendMessage = (data: { channelId: string; message: string; messageTs?: string }): Promise<{ channelId: string; messageTs: string }> => {
	return requestAPI('/admin/santa/message', 'POST', { 'Content-type': 'application/json' }, JSON.stringify({ ...data }));
};

const editMessage = (data: { channelId: string; messageTs: string; message: string }): Promise<{ channelId: string; messageTs: string }> => {
	return requestAPI('/admin/santa/message', 'PUT', { 'Content-type': 'application/json' }, JSON.stringify({ ...data }));
};

const deleteMessage = (data: { channelId: string; messageTs: string }): Promise<{ channelId: string; messageTs: string }> => {
	return requestAPI('/admin/santa/message', 'DELETE', { 'Content-type': 'application/json' }, JSON.stringify({ ...data }));
};

const SantaAdmin = () => {
	const user = useContext(UserContext);
	const [text, setText] = useState('');
	const [channelId, setChannelId] = useState('');
	const [lastMessageTs, setLastMessageTs] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	// hide UI from everyone else. Validation is still done on the backend.
	const isGraybeard = ['matej.skrbis@inova.si', 'dominik.korosec@inova.si'].includes(user?.email ?? '');

	if (!isGraybeard) {
		return <></>;
	}

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setText(e.target.value);
	};

	return <div style={{}}>
		<p>Napiši sporočilo kot 🎅🏻:</p>
		<p>V kanal (ID): <input type='text' value={channelId} onChange={(evt) => setChannelId(evt.target.value)}></input></p>
		<p>sklic / message_ts (opcijsko): <input type='text' value={lastMessageTs} onChange={(evt) => setLastMessageTs(evt.target.value)}></input> </p>
		<p><input type='button' onClick={() => setChannelId('C065RTX0PU0')} value='(DEV) AOC'></input><input type='button' onClick={() => setChannelId('C05R38QL88H')} value='(PROD) AOC'></input><input type='button' onClick={() => setChannelId('C03HA2RH3')} value='General'></input></p>
		<textarea
			cols={40}
			rows={5}
			value={text}
			spellCheck={'false'}
			onChange={handleChange} />

		<p>
			<Button disabled={loading} onClick={async () => {
				setLoading(true);
				const response = await sendMessage({ channelId, message: text, ...(lastMessageTs ? { messageTs: lastMessageTs } : {}) });

				setLastMessageTs(response.messageTs);
				setLoading(false);
			}}>
				{lastMessageTs ? 'Thread' : 'Pošlji'}
			</Button>
			{lastMessageTs ? <Button disabled={loading} color='warning' onClick={async () => {
				setLoading(true);
				const response = await editMessage({ channelId, messageTs: lastMessageTs, message: text });

				setLastMessageTs(response.messageTs);
				setLoading(false);
			}}>Posodobi</Button> : <></>}
			{lastMessageTs ? <Button disabled={loading} color='error' onClick={async () => {
				setLoading(true);
				await deleteMessage({ channelId, messageTs: lastMessageTs });
				setLastMessageTs('');
				setLoading(false);
			}}>Izbriši</Button> : <></>}
		</p>
	</div >;
};

export default SantaAdmin;
