import Button from '@mui/material/Button';
import DialogMUI from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

interface DialogProps {
	title: string;
	content: string;
	onClose: () => void;
}

const Dialog: React.FC<DialogProps> = (props) => {
	const { title, content, onClose } = props;

	return <>
		<DialogMUI
			open={true}
			onClose={onClose}
			aria-labelledby="dialog-title"
			aria-describedby="dialog-description"
		>
			<DialogTitle id="dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="dialog-description">{content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} autoFocus>OK</Button>
			</DialogActions>
		</DialogMUI>
	</>;
};

export default Dialog;
