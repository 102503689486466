import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { styled } from '@mui/system';
import { COMPLETED_SNOWFLAKE_COLOR, MAX_CONTAINER_WIDTH_PX, SNOWFLAKE_COLOR } from '../../styles/AOCTheme';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
	fetchTasksPreview,
	selectTasksPreview,
	selectTasksLoading,
	showHideInfoDialog,
	selectNotificationsHintState,
} from 'state/tasks/tasksSlice';
import UnlockTimer from 'components/UnlockTimer';
import { formatDate } from 'utils/dateUtils';
import {
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import Loading from 'components/Loading';
import { getShirtForUser } from 'API/shirts';
import AOCError from 'API/AOCError';
import { shirtsErrorHandler } from 'utils/shirtsErrorHandler';
import { ParamTypes } from 'types/ParamTypes';
import { useIsInternalUser } from 'hooks/useIsInternalUser';

const StyledDiv = styled('div')(() => ({
	padding: '32px 16px 0 16px',
	maxWidth: MAX_CONTAINER_WIDTH_PX,
}));

const Tasks = () => {
	const isLoading = useAppSelector(selectTasksLoading);
	const { eventId } = useParams() as ParamTypes;
	const tasks = useAppSelector((state) => selectTasksPreview(state, eventId));
	const showNotificationsHint = useAppSelector(selectNotificationsHintState);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const ref = useRef<{ [id: string]: boolean }>({});
	const [, setHoverLeaderboard] = useState(false);
	const fetchAllTasks = useCallback(() => {
		dispatch(fetchTasksPreview(eventId));
	}, [dispatch, eventId]);
	const isInternalUser = useIsInternalUser();

	useEffect(() => {
		fetchAllTasks();
	}, [fetchAllTasks]);

	useEffect(() => {
		if (!isInternalUser) {
			return;
		}

		const checkIfValid = async () => {
			try {
				const data = await getShirtForUser(eventId);

				if (data.canGetShirt && !data.shirtSize) {
					dispatch(
						showHideInfoDialog({
							title: 'Obvestilo ✨',
							message:
								'Božiček te želi spomniti, da še nisi izbral velikosti majice. To lahko storiš v zavihku [NAGRADE] 🎅.',
						}),
					);
				}
			} catch (e: any) {
				if (e instanceof AOCError) {
					if (e.errorCode === 'USER_NOT_FOUND') {
						dispatch(
							showHideInfoDialog({ title: 'Error', message: 'User not found.' }),
						);
					} else {
						shirtsErrorHandler(e);
					}
				} else {
					dispatch(showHideInfoDialog({ title: 'Error', message: e.message }));
				}
			}
		};

		checkIfValid();
	}, [dispatch, eventId, isInternalUser]);

	const handleTaskLeaderboardClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		id: string | null,
	) => {
		event.stopPropagation();
		if (id) {
			navigate(`/events/${eventId}/leaderboard/tasks/${id}`);
		}
	};

	const eventNotReady = tasks && tasks.every((task) => !task.isAvailable);
	const displayNotificationHint = showNotificationsHint && eventNotReady;
	const displayNotificationAcceptedHint = showNotificationsHint === false && eventNotReady;

	return (
		<StyledDiv>
			{/* Show loader. */}
			{!tasks && isLoading && <Loading />}

			{/* List all challenges. */}
			{displayNotificationHint ? <h3>Želiš, da te ob prihodu nalog obvesti Božiček 🎅? Potem klikni “🔔” desno zgoraj.</h3> : (
				displayNotificationAcceptedHint ? <h3>Božiček te bo obvestil ob prihodu nalog 🎅!</h3> : null)}
			<List>
				{tasks && tasks.map(({ id, name, unlockDate, isAvailable, completed, type }, index) => <ListItem secondaryAction={
					isAvailable && unlockDate &&
					<ListItemButton style={{ padding: 0, backgroundColor: 'transparent' }}>
						<ListItemIcon sx={{
							justifyContent: 'right',
							color: ref.current[id!] === true ? COMPLETED_SNOWFLAKE_COLOR : SNOWFLAKE_COLOR,
						}}
						onMouseOver={() => {
							if (id) ref.current[id] = true;
							setHoverLeaderboard(true);
						}}
						onMouseLeave={() => {
							if (id) ref.current[id] = false;
							setHoverLeaderboard(false);
						}}
						onClick={(event) => handleTaskLeaderboardClick(event, id)}
						>

							{isAvailable && unlockDate && <p>[LESTVICA]</p>}
						</ListItemIcon>
					</ListItemButton>
				} key={id ?? index} disablePadding onClick={() => id ? navigate(`/events/${eventId}/tasks/${id}`) : null} disabled={id === null}>
					<ListItemButton style={isAvailable ? {} : { cursor: 'not-allowed' }}>
						<ListItemIcon>
							{type === 'normal' && <AcUnitIcon color={completed ? 'success' : 'inherit'} />}
							{type === 'evaluated' && <AutoAwesomeIcon color={completed ? 'success' : 'inherit'} />}
						</ListItemIcon>
						<div>
							{name && <><ListItemText primary={name} />{formatDate(unlockDate, 'dd. LLLL y HH:mm')}</>}
							{!isAvailable && unlockDate && <>Izziv se odklene:&nbsp;<UnlockTimer unlockDate={unlockDate} showPrecisionIfRequired={!(tasks[index - 1]?.isAvailable ?? true) && !isAvailable} fetchAllTasks={fetchAllTasks} includeAbsoluteTime /></>}
						</div>
					</ListItemButton>
				</ListItem>)}
			</List>
		</StyledDiv>
	);
};

export default Tasks;
