import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledTypography = styled(Typography)(() => ({
	fontSize: '18px!important',
	color: 'red',
}));

const StyledListItem = styled(ListItem)(() => ({
	fontSize: '1.0rem',
}));

const Credits = () => {
	return (<div style={{ margin: '16px 0 0 16px', fontFamily: '\'Press Start 2P\'' }}>
		<List style={{ paddingTop: '0' }}>
			<StyledTypography variant='h4'>Božični dekoratorji:</StyledTypography>
			<StyledListItem>* Viktorija Talevska Kuzmanova</StyledListItem>
			<StyledListItem>* Žiga Lah</StyledListItem>
			<StyledListItem>* Dominik Korošec</StyledListItem>
			<StyledListItem>* Matej Skrbiš</StyledListItem>
			<StyledListItem>* Julija Pestiček</StyledListItem>
		</List>
		<List style={{ paddingTop: '0' }}>
			<StyledTypography variant='h4'>Pomagači v delavnici:</StyledTypography>
			<StyledListItem>* Dominik Korošec</StyledListItem>
			<StyledListItem>* Matej Skrbiš</StyledListItem>
			<StyledListItem>* Jakob Polegek</StyledListItem>
		</List>
		<List style={{ paddingTop: '0' }}>
			<StyledTypography variant='h4'>Arhitekti zgodb:</StyledTypography>
			<StyledListItem>* Dominik Korošec</StyledListItem>
			<StyledListItem>* Matej Skrbiš</StyledListItem>
		</List>
	</div>);
};

export default Credits;
