import { requestAPI } from './requestApi';

export const getTasksPreview = (eventId: string) => requestAPI(`/events/${encodeURI(eventId)}/tasks-preview/`);

export const getTaskAvailableParts = (eventId: string, taskId: string) => requestAPI(`/events/${encodeURI(eventId)}/tasks/${encodeURI(taskId)}/available-parts`);

export const getTaskPartDetails = (eventId: string, taskId: string, partNumber: number) => requestAPI(`/events/${encodeURI(eventId)}/tasks/${encodeURI(taskId)}/part/${partNumber}`);

export const submitTaskPartSolution = (eventId: string, taskId: string, partNumber: number, solution: string | Uint8Array) => {
	const url = `/events/${encodeURI(eventId)}/tasks/${encodeURI(taskId)}/part/${partNumber}`;

	if (solution instanceof Uint8Array) {
		return requestAPI(url, 'POST', { 'Content-type': 'application/octet-stream' }, solution);
	}

	return requestAPI(url, 'POST', { 'Content-type': 'application/json' }, JSON.stringify({ solution }));
};

export const getTaskInput = (eventId: string, taskId: string, partNumber: string | number) => requestAPI(`/events/${encodeURI(eventId)}/tasks/${encodeURI(taskId)}/part/${partNumber}/input`, 'GET', undefined, undefined, { followRedirects: true }) as unknown as Response;

export const getTaskHistory = (eventId: string, taskId: string, partNumber: string | number) => requestAPI(`/events/${encodeURI(eventId)}/tasks/${encodeURI(taskId)}/part/${partNumber}/history`, 'GET') as unknown as Promise<{ text: string }>;

export const callSpecialEvaluatorData = (eventId: string, taskId: string, partNumber: string | number, command: { name: string; data: Record<string, any> | null }) => requestAPI(`/events/${encodeURI(eventId)}/tasks/${encodeURI(taskId)}/part/${partNumber}/special-evaluator-data`, 'POST', { 'Content-type': 'application/json' }, JSON.stringify({ command }));
