import type { User } from 'firebase/auth';
import { UserContext } from 'components/App';
import { useContext } from 'react';

export const useIsInternalUser = () => {
	const userToken = useContext(UserContext);

	return isUserInternal(userToken);
};

export const isUserInternal = (user: User | null) => {
	return user?.email?.endsWith('@inova.si') ?? false;
};
