export class AnimationFrameFpsLock {
	public onRender: (nowTs: number, frameIdx: number) => void;
	private requiredDiffMs: number;
	private rafHandle: number;

	constructor(args: { maxFps: number; onRender: (nowTs: number, frameIdx: number) => void }) {
		this.requiredDiffMs = 1000 / args.maxFps;
		this.onRender = args.onRender;
		this.rafHandle = 0;
	}

	start() {
		let lastRenderTs = 0;
		let frameIdx = 0;
		const tolerance = 1;
		const loop = (nowTs: number) => {
			this.rafHandle = requestAnimationFrame(loop);
			if (nowTs - lastRenderTs < this.requiredDiffMs - tolerance) {
				return;
			}
			lastRenderTs = nowTs;
			this.onRender(nowTs, ++frameIdx);
		};

		loop(0);
	}

	destroy() {
		cancelAnimationFrame(this.rafHandle);
	}
}
