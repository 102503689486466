import { GoogleAuthProvider, signInWithPopup, User } from 'firebase/auth';
import { FirebaseError } from '@firebase/app';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import { CANDYCANE_RED, PRIMARY } from 'styles/AOCTheme';
import Header from '../Header';
import { auth } from '../../config/initFirebase';
import mapFirebaseAuthErrorCodesToDescription from 'utils/mapFirebaseAuthErrorCodesToDescription';
import { showHideInfoDialog } from 'state/tasks/tasksSlice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { useCallback } from 'react';
import { ParamTypes } from 'types/ParamTypes';
import { selectEvent } from 'state/events/eventsSlice';
import { getCurrentSeasonInfo } from '../Background/season';

interface IBasicLayout {
	user: User | null;
	showProjectTitleAndDate?: boolean;
	children?: React.ReactNode;
}

const prefix = 'BasicLayout';
const classes = {
	loginSpan: `${prefix}-loginSpan`,
	contentContainer: `${prefix}-contentContainer`,
	glowOn: `${prefix}-glowOn`,
};
const StyledDiv = styled('div')(() => ({
	overflowY: 'auto',
	'&::-webkit-scrollbar': {
		'-webkit-appearance': 'none',
		width: '4px',
	},
	'&::-webkit-scrollbar-thumb': {
		borderRadius: '62px',
		backgroundColor: 'rgba(255, 255, 255, .2)',
		boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
	},
}));

const StyledSpan = styled('span')(() => ({
	[`&.${classes.loginSpan}`]: {
		'&:hover': {
			cursor: 'pointer',
		},
		color: PRIMARY,
		textShadow: `0 0 5px ${PRIMARY}`,
	},
	[`&.${classes.glowOn}`]: {
		'&:hover': {
			textShadow: `0 0 10px ${CANDYCANE_RED}`,
			transition: 'text-shadow 0.3s ease-in-out',
		},
	},
}));

const seasonInfo = getCurrentSeasonInfo();
const colors = seasonInfo.letterColorSequence;

const splitStringWithOffsetSpaces = (str: string) =>
	str.split('').reduce((acc, chr, i, r) =>
		[...acc, { chr, i: r[i - 1] === ' ' ? (acc.at(-1)?.i ?? 0) : (acc.at(-1)?.i ?? -1) + 1 }],
		[] as { chr: string; i: number }[]);

const BasicLayout: React.FC<IBasicLayout> = (props) => {
	const navigate = useNavigate();
	const { user, children, showProjectTitleAndDate = true } = props;
	const current = new Date();
	const { eventId } = useParams() as ParamTypes;
	const handleEventHomeRedirect = useCallback(() => {
		if (location.pathname.endsWith('/tasks-preview') || location.pathname.endsWith('/events')) {
			return;
		}
		if (eventId) {
			navigate(`/events/${eventId}`);
		} else {
			navigate('/events');
		}
	}, [navigate, eventId]);
	const event = useAppSelector((state) => selectEvent(state, eventId));
	const date = `${current.getDate()}.${current.getMonth() + 1}.${current.getFullYear()}`;
	const dispatch = useAppDispatch();

	const signInWithGooglePopUp = useCallback(async () => {
		const provider = new GoogleAuthProvider();

		provider.setCustomParameters({
			prompt: 'select_account',
		});

		try {
			await signInWithPopup(auth, provider);
			navigate('/events');
		} catch (e) {
			if (e instanceof Error) {
				if (['auth/popup-closed-by-user', 'auth/cancelled-popup-request'].includes((e as FirebaseError).code)) {
					// eslint-disable-next-line no-console
					console.log(e);

					return;
				}
				dispatch(showHideInfoDialog({ title: 'Napaka', message: mapFirebaseAuthErrorCodesToDescription(e.message) }));
			}
			// eslint-disable-next-line no-console
			console.log(e);
		}
	}, [dispatch, navigate]);

	return (
		<>
			<Header signIn={signInWithGooglePopUp} />
			<StyledDiv>
				{showProjectTitleAndDate && <div style={{ padding: '0 16px' }}>
					<StyledSpan className={classes.glowOn}><Typography onClick={() => handleEventHomeRedirect()} component='div' variant='h3' style={{ userSelect: 'none', cursor: 'pointer', marginTop: '16px', fontSize: '50px' }}>{splitStringWithOffsetSpaces(event?.name ?? seasonInfo.mainTitle).map((v, i) => (<span style={{ color: colors[v.i % colors.length] }} key={i}>{v.chr}</span>))}</Typography></StyledSpan>
					<Typography component='div' variant='body2' style={{ marginTop: '16px' }}>
						{event
							? event.finished
								? <span style={{ color: 'red' }}>Lestvica se je zamrznila {new Date(event.endsOnDate!).toLocaleString('sl-SI').replace(',', ' ob ')}</span>
								: <span style={{ color: '#4AF626' }}>Lestvica se zamrzne {new Date(event.endsOnDate!).toLocaleString('sl-SI').replace(',', ' ob ')}</span>
							: `Današnji datum: ${date}`
						}</Typography>
					{!user && <Typography component='div' variant='h6' style={{ fontFamily: '\'Press Start 2P\', \'cursive\'', fontSize: '1rem', marginTop: '16px' }}><StyledSpan className={classes.loginSpan} onClick={signInWithGooglePopUp}>Prijavi se</StyledSpan> {seasonInfo.loginInfoAdditionalText}</Typography>}
				</div>}
				{children}
			</StyledDiv>
		</>
	);
};

export default BasicLayout;
