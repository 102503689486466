import { requestAPI } from './requestApi';

export const getUserProfile = () => requestAPI('/user') as Promise<{ profile: { notifications?: boolean; isPublic?: boolean; emoji?: string; displayName?: string; isMysterious?: boolean }; emojiList?: string[] }>;

export function setUserProfile(data: { emoji: string; isPublic: boolean; isMysterious: boolean }) {
	return requestAPI('/user', 'POST', { 'Content-type': 'application/json' }, JSON.stringify({ ...data }));
}

export function userUnsubscribeNotifications(data: { token: string | null }) {
	return requestAPI('/user/notification-subscription', 'DELETE', { 'Content-type': 'application/json' }, JSON.stringify({ ...data }));
}

export function userSubscribeNotifications(data: { token: string }) {
	return requestAPI('/user/notification-subscription', 'POST', { 'Content-type': 'application/json' }, JSON.stringify({ ...data }));
}
