/**
 * DISCLAIMER: I'm truly sorry for what you're about to witness here.
 * This code is the unfortunate quick copy-paste-modify of santa-sleighs.ts
 * But hey, deadlines don’t wait, and neither does Halloween! 🎃
 *
 * Proceed at your own risk. You've been warned.
 * - Dominik
 */

import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';

const BASE_SCALE = 8;
let SCALE = BASE_SCALE + BASE_SCALE * 0.5;
const animationSpeedUpdate = 0.011 * 1.5;
const witchSpeed = 0.8;
const checkIfInEventsRegex = /events\/.+/;

export async function initializeWitch(mainScene: THREE.Scene, camera: THREE.OrthographicCamera) {
	const GROUP = new THREE.Group();
	const globalModelLoader = new GLTFLoader();
	const modelBufferPromises: { [url: string]: Promise<ArrayBuffer> } = {};

	async function globalLoadModel(url: string): Promise<GLTF> {
		const bufferPromise = modelBufferPromises[url] ??= fetch(url).then((response) => response.arrayBuffer());

		return await globalModelLoader.parseAsync(await bufferPromise, '');
	}

	async function loadAndInitializeModel(url: string) {
		const gltf = await globalLoadModel(url);
		const model = gltf.scene;
		const mixer = new THREE.AnimationMixer(model);

		gltf.animations.forEach((clip) => {
			mixer.clipAction(clip).play();
		});

		return { model, mixer, gltf };
	}

	async function createWitch() {
		const witchUrl = 'https://firebasestorage.googleapis.com/v0/b/inovait-advent-of-code.appspot.com/o/AOC_STATIC%2Fwitch.glb?alt=media&token=88a3a731-f1b4-4ce2-aad4-6032049d4f7d';
		let trailBuffer: any[] = [];
		const pivot = new THREE.Object3D();
		const { model: witch1, mixer: mixer1 } = await loadAndInitializeModel(witchUrl);

		witch1.position.x = 0.0;
		mixer1.setTime(1.5);
		pivot.add(witch1);

		return {
			_hoverAnimIdx: Math.floor(Math.random() * 1000),
			_wiggleIdx: 0,
			speed: 0,
			_angle: 0,
			_rotation: 0,
			_velocity: { x: 1, y: 0, z: 0 },
			_forwardVector: null as THREE.Vector3 | null,
			_nosePosition: null as THREE.Vector3 | null,
			_noseIntensity: null as number | null,
			getMesh() {
				return pivot;
			},
			getVelocity() {
				return this._velocity;
			},
			setRotation(rotation: number) {
				this._rotation = rotation;
			},
			setAngle(angle: number) {
				this._angle = angle;
				const dx = Math.cos(angle);
				const dy = Math.sin(angle);

				this._velocity.x = dx;
				this._velocity.y = dy;
			},
			getAngle() {
				return this._angle;
			},
			position: { x: 0, y: 0, z: 0 },
			addToScene(scene: THREE.Scene | THREE.Group) {
				scene.add(pivot);
			},
			update() {
				this._hoverAnimIdx += 0.1;
				mixer1.update(animationSpeedUpdate);

				this.position.x += this._velocity.x * this.speed;
				this.position.y += this._velocity.y * this.speed;
				this.position.z += this._velocity.z * this.speed;

				this._wiggleIdx += 2;
				const wiggleScale = Math.sin(this._wiggleIdx / 200 * 2 * Math.PI) / 100 * 300;
				const leftSidePerp = new THREE.Vector2(-this._velocity.y, this._velocity.x).normalize().multiplyScalar(wiggleScale);

				pivot.position.x = this.position.x + leftSidePerp.x;
				pivot.position.y = this.position.y + leftSidePerp.y;
				pivot.position.z = this.position.z;
				trailBuffer.push({ rotation: this._rotation, angle: this._angle, position: { x: pivot.position.x, y: pivot.position.y, z: pivot.position.z } });

				const zRotation = this._angle;
				const xRotation = this._rotation;

				const yQuaternion = new THREE.Quaternion();

				yQuaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / 2);
				const zQuaternion = new THREE.Quaternion();

				zQuaternion.setFromAxisAngle(new THREE.Vector3(0, 0, 1), zRotation);
				const xQuaternion = new THREE.Quaternion();

				xQuaternion.setFromAxisAngle(new THREE.Vector3(1, 0, 0), xRotation);
				pivot.quaternion.copy(zQuaternion).multiply(xQuaternion).multiply(yQuaternion);

				const offsetA = Math.sin(this._hoverAnimIdx / 10) * 0.1;

				witch1.position.y = offsetA;
				witch1.position.z = 0;
				const degreesHoverMax = 15;
				const hover1Speed = this._hoverAnimIdx * 2;
				const hoverAngleDelta1 = Math.sin((hover1Speed) / 10) * Math.PI / 180 * degreesHoverMax;

				witch1.rotation.z = hoverAngleDelta1;

				const upVec = new THREE.Vector3();

				upVec.copy(pivot.up).applyQuaternion(pivot.quaternion);
				if (upVec.y < 0 || Math.abs(upVec.z) > 0.01) {
					this._rotation += 0.05;
				} else {
					this._rotation = Math.PI * Math.round(this._rotation / Math.PI);
				}
			},
			getTrailBuffer() {
				return trailBuffer;
			},
			clearTrailBuffer() {
				trailBuffer = [];
			},
		};
	}

	const witch = await createWitch();
	let isWitchInFOV = false;
	let mouseClick: { vec: THREE.Vector3; commitFrames: number } | null = null;

	function onClick(event: MouseEvent) {
		const raycaster = new THREE.Raycaster();
		const mouse = new THREE.Vector2();

		mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
		mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
		raycaster.setFromCamera(mouse, camera);
		const planeZ = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
		const point = new THREE.Vector3();

		raycaster.ray.intersectPlane(planeZ, point);
		mouseClick = { vec: point.multiplyScalar(1 / SCALE), commitFrames: 60 * 6 /* ~6s if ran at 60fps */ };
	}

	function vector3({ x, y, z }: { x: number; y: number; z: number }) {
		return new THREE.Vector3(x, y, z);
	}

	const isGroupVisibleFrustum = new THREE.Frustum();
	const isGroupVisibleMatrix = new THREE.Matrix4();
	const isGroupVisibleBox3 = new THREE.Box3();

	function isGroupVisible(group: THREE.Group, camera: THREE.OrthographicCamera) {
		group.updateMatrixWorld();
		const box = isGroupVisibleBox3.setFromObject(group);

		box.min.x -= SCALE * 10 * 10;
		box.min.y -= SCALE * 10 * 10;
		box.max.x += SCALE * 10 * 10;
		box.max.y += SCALE * 10 * 10;
		isGroupVisibleMatrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
		isGroupVisibleFrustum.setFromProjectionMatrix(isGroupVisibleMatrix);

		return isGroupVisibleFrustum.intersectsBox(box);
	}

	function onUpdate() {
		if (mouseClick && --mouseClick.commitFrames < 0) {
			mouseClick = null;
		}

		if (mouseClick) {
			const clickV3 = vector3(mouseClick.vec);
			const witchV3 = vector3(witch.position);

			clickV3.sub(witchV3).normalize();
			const witchVelocity = witch.getVelocity();

			if (!isWitchInFOV && !witch.speed) {
				rngScale();
				witch.clearTrailBuffer();
				const angle = Math.atan2(clickV3.y, clickV3.x);

				witch.setAngle(angle);
				witch._rotation = Math.abs(angle) > Math.PI / 2 ? Math.PI : 0;
				witch.speed = witchSpeed;
				mouseClick = null;
			} else {
				const direction = (new THREE.Vector2(witchVelocity.x, witchVelocity.y)).cross(new THREE.Vector2(clickV3.x, clickV3.y));
				const witchAngle = witch.getAngle();

				const steerStrength = 1.5;

				witch.setAngle(witchAngle + (direction > 0 ? Math.PI / 180 : -Math.PI / 180) * steerStrength);

				if (Math.abs(direction) < 0.005) mouseClick = null;
			}
		}

		witch.update();

		if (isGroupVisible(GROUP, camera)) {
			if (!isWitchInFOV) {
				witch.speed = witchSpeed;
			}
			isWitchInFOV = true;
		} else {
			if (isWitchInFOV) {
				witch.speed = 0;
				const shouldAutopilotWitch = !mouseClick && !checkIfInEventsRegex.test(window.location.pathname);

				if (shouldAutopilotWitch) {
					const w = window.innerWidth;
					const h = window.innerHeight;
					const randSign = () => (Math.random() * 2 - 1);
					const rngw = w * 0.5 + (w * 0.4 * randSign());
					const rngh = h * 0.5 + (h * 0.4 * randSign());

					onClick({ clientX: rngw, clientY: rngh } as MouseEvent);
				}
			}
			isWitchInFOV = false;
		}
	}

	const light = new THREE.AmbientLight(0xaaaaaa, 1);

	GROUP.add(light);
	witch.addToScene(GROUP);
	mainScene.add(GROUP);
	const v = (new THREE.Vector3(1, 1, 1)).multiplyScalar(SCALE);

	GROUP.scale.set(v.x, v.y, v.z);

	function rngScale() {
		const oldScale = SCALE;

		SCALE = BASE_SCALE + Math.floor(Math.sin(Math.PI * 2 * Math.random()) * BASE_SCALE * 0.5);
		const v = (new THREE.Vector3(1, 1, 1)).multiplyScalar(SCALE);

		GROUP.scale.set(v.x, v.y, v.z);
		witch.clearTrailBuffer();
		witch.position.x *= (oldScale / SCALE);
		witch.position.y *= (oldScale / SCALE);
		witch.position.z = -5;
	}

	const state = {
		onUpdate,
		setVisibility(visible: boolean) {
			GROUP.visible = visible;
		},
		reset() {
			witch.position.x = -5 * SCALE * (1 / SCALE);
			witch.position.y = -window.innerHeight * (1 / SCALE);
			witch.position.z = -5;
			witch.clearTrailBuffer();
			witch.setAngle(Math.PI / 16);
		},
		onClick,
	};

	state.reset();

	return state;
}
