import RainBackground from '../RainBackground';
import SnowBackground from '../SnowBackground';
import { getCurrentSeason, Season } from './season';

const Background = () => {
	switch (getCurrentSeason()) {
		case Season.Halloween:
			return (<RainBackground />);
		case Season.Christmas:
		default:
			return (<SnowBackground />);
	}
};

export default Background;
