import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useParams, useLocation, Outlet } from 'react-router-dom';
import { fetchEvent } from 'state/events/eventsSlice';
import { ParamTypes } from 'types/ParamTypes';
import { AppDispatch } from '../../state/store';

const EventDetails: React.FC = () => {
	const { eventId } = useParams() as ParamTypes;
	const dispatch = useDispatch<AppDispatch>();
	const location = useLocation();

	useEffect(() => {
		dispatch(fetchEvent({ eventId }));
	}, [dispatch, eventId]);

	if (location.pathname == `/events/${ eventId}`) {
		return <Navigate replace to={`/events/${eventId}/tasks-preview`}/>;
	}

	return <Outlet/>;
};

export default EventDetails;
