import { createSlice, createAsyncThunk, createSelector, PayloadAction } from '@reduxjs/toolkit';
import AOCError from 'API/AOCError';
import { getTasksPreview, getTaskAvailableParts } from 'API/tasks';
import { RootState } from 'state/store';
import { Task, TaskPreview } from 'types/Task';

interface TaskInfo { message: string; title: string };
interface TasksState {
	tasksPreview: { [eventId: string]: TaskPreview[] | undefined };
	tasks: { [taskId: string]: Task | undefined };
	isLoading: boolean;
	infoDialog: TaskInfo | undefined;
	notificationsHint: boolean | undefined;
}

const initialState: TasksState = {
	tasksPreview: {},
	isLoading: true,
	tasks: {},
	infoDialog: undefined,
	notificationsHint: undefined,
};

export const fetchTasksPreview = createAsyncThunk<{ eventId: string; tasks: TaskPreview[] | undefined }, string, { rejectValue: TaskInfo }>(
	'tasks/fetchTasksPreview',
	async (eventId: string, thunkAPI) => {
		try {
			const tasks: TaskPreview[] = await getTasksPreview(eventId);

			return { eventId, tasks };
		} catch (e) {
			if (e instanceof AOCError) {
				return thunkAPI.rejectWithValue({ title: 'Napaka', message: e.message });
			}

			return thunkAPI.rejectWithValue({ title: 'Napaka', message: 'Oops, nekaj je šlo narobe :(.' });
		}
	},
);

export const fetchTask = createAsyncThunk<Task, { eventId: string; taskId: string }, { rejectValue: TaskInfo }>(
	'tasks/fetchTask',
	async ({ eventId, taskId }, thunkAPI) => {
		try {
			const task: Task = await getTaskAvailableParts(eventId, taskId);

			return task;
		} catch (e) {
			if (e instanceof AOCError) {
				return thunkAPI.rejectWithValue({ title: 'Napaka', message: e.message });
			}

			return thunkAPI.rejectWithValue({ title: 'Napaka', message: 'Oops, nekaj je šlo narobe :(.' });
		}
	},
);

const tasksSlice = createSlice({
	name: 'tasks',
	initialState,
	reducers: {
		showHideInfoDialog: (state, action: PayloadAction<TaskInfo | undefined>) => {
			state.infoDialog = action.payload;
		},
		showHideNotificationHintState: (state, action: PayloadAction<boolean | undefined>) => {
			state.notificationsHint = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchTasksPreview.fulfilled, (state, action) => {
			state.isLoading = false;
			state.tasksPreview[action.payload.eventId] = action.payload.tasks;
		});
		builder.addCase(fetchTasksPreview.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(fetchTasksPreview.rejected, (state, action) => {
			state.isLoading = false;
			state.infoDialog = action.payload;
		});
		builder.addCase(fetchTask.fulfilled, (state, action) => {
			state.isLoading = false;
			state.tasks[action.payload.id] = action.payload;
		});
		builder.addCase(fetchTask.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(fetchTask.rejected, (state, action) => {
			state.isLoading = false;
			state.infoDialog = action.payload;
		});
	},
});

export const selectTasksPreview = createSelector(
	[
		(state: RootState) => state.tasks.tasksPreview,
		(state: RootState, eventId:string) => eventId,
	],
	(tasksPreview, eventId) => tasksPreview[eventId],
);

export const selectTask = createSelector(
	[
		(state: RootState) => state.tasks.tasks,
		(state: RootState, taskId: string) => taskId,
	],
	(tasks, taskId) => tasks[taskId],
);

export const selectInfoDialog = createSelector(
	(state: RootState) => state.tasks.infoDialog,
	(infoDialog) => infoDialog,
);

export const selectNotificationsHintState = createSelector(
	(state: RootState) => state.tasks.notificationsHint,
	(hintState) => hintState,
);

export const selectTasksLoading = createSelector(
	(state: RootState) => state.tasks.isLoading,
	(isLoading) => isLoading,
);

export const { showHideInfoDialog, showHideNotificationHintState } = tasksSlice.actions;

export default tasksSlice.reducer;
