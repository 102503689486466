import { callSpecialEvaluatorData } from 'API/tasks';
import * as THREE from 'three';
import { fragmentShaderPainting, vertexShaderPainting } from './shaders';

export const createImageContainer = (eventId: string, taskId: string, partNumber: number) => ({
	onScreenImages: 3,
	expectedPerPage: 10,
	fetching: false,
	emptySearchResult: false,
	missingResult: {
		buffer: new Uint8Array(1).fill(127), // something that is not uint8max, so that shader will render as transparent.
		width: 1,
		height: 1,
	},
	imageResults: [] as { image: { width: number; height: number; buffer: Uint8Array }; insertIsoTime: string | undefined }[],
	async downloadImage(url: string): Promise<{ width: number; height: number; buffer: Uint8Array }> {
		const result = await fetch(url);
		const blob = await result.blob();
		const buffer = new Uint8Array(await blob.arrayBuffer());
		const width = buffer[0] | (buffer[1] << 8);
		const height = buffer[2] | (buffer[3] << 8);
		const imageData1D = new Uint8Array(width * height);
		const dataOffset = 4;

		for (let y = 0; y < height; y++) {
			for (let x = 0; x < width; x++) {
				const byteIdx = Math.floor((y * width + x) / 8) + dataOffset;
				const bitPosition = 8 - ((y * width + x) % 8) - 1;
				const pixelValue = (buffer[byteIdx] >>> bitPosition) & 1;

				imageData1D[y * width + x] = pixelValue ? 0 : 255;
			}
		}

		return { width, height, buffer: imageData1D };
	},
	async init() {
		const maxPageSize = await this.fetchNext();
		const imgResultsCpy = [...this.imageResults];

		while (this.imageResults.length < maxPageSize) {
			this.imageResults.push(...imgResultsCpy);
		}
	},
	async fetchNext() {
		const { maxPageSize, images: nextImages } = await callSpecialEvaluatorData(eventId, taskId, partNumber, {
			name: 'paginate-latest-images', data: {
				previousTs: this.imageResults.at(-1)?.insertIsoTime,
			},
		});

		this.emptySearchResult = nextImages.length === 0;
		const images = await Promise.all(nextImages.map(async (result: any) => ({
			insertIsoTime: result.insertIsoTime,
			image: await this.downloadImage(result.publicUrl),
		})));

		this.imageResults.push(...images);
		const lastInsertIsoTime = this.imageResults.at(-1)?.insertIsoTime;

		while (this.imageResults.length < this.onScreenImages) {
			this.imageResults.push({
				insertIsoTime: lastInsertIsoTime,
				image: this.missingResult,
			});
		}

		return maxPageSize;
	},
	_pull(): (typeof this.imageResults)[0]['image'] {
		if (this.imageResults.length <= this.onScreenImages && !this.fetching) {
			this.fetching = true;
			this.fetchNext().finally(() => this.fetching = false);
		}
		const next = this.imageResults.shift()?.image ?? this.missingResult;

		return next;
	},
	pullNextDataOnly(): THREE.DataTexture {
		const next = this._pull();
		const { buffer, width, height } = next;
		const matrixTexture = new THREE.DataTexture(buffer, width, height, THREE.RedFormat, THREE.UnsignedByteType);

		matrixTexture.needsUpdate = true;

		return matrixTexture;
	},
	pullNext(): THREE.ShaderMaterial {
		const next = this._pull();
		const { buffer, width, height } = next;

		const matrixTexture = new THREE.DataTexture(buffer, width, height, THREE.RedFormat, THREE.UnsignedByteType);

		matrixTexture.needsUpdate = true;

		return new THREE.ShaderMaterial({
			uniforms: {
				u_matrix: { value: matrixTexture },
				u_resolution: { value: new THREE.Vector2(width, height) },
			},
			vertexShader: vertexShaderPainting,
			fragmentShader: fragmentShaderPainting,
		});
	},

});
