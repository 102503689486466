export const firebaseConfig = {
	firebase: {
		apiKey: 'AIzaSyCz_yLA07C9y_I2iglBbBfBv6u09UV137c',
		authDomain: 'inovait-advent-of-code.firebaseapp.com',
		projectId: 'inovait-advent-of-code',
		storageBucket: 'inovait-advent-of-code.appspot.com',
		messagingSenderId: '645342350789',
		appId: '1:645342350789:web:f6b7efb9f8673901828fac',
	},
	baseURL: 'https://europe-west1-inovait-advent-of-code.cloudfunctions.net/app',
	vapidKey: 'BKyKytpilwCIDTJHp5nIJti6-Zm2lA1KijvK81s59zGXPu4JH3VrYTysW4_2EJ4Untwx_Rq00VreU5zKa87sPDQ',
};
export default firebaseConfig;
