import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Typography, Checkbox, Select, MenuItem, FormControl, OutlinedInput, Button } from '@mui/material';
import { styled } from '@mui/system';
import { UserContext } from 'components/App';
import { useCallback, useContext, useEffect, useState } from 'react';
import { getUserProfile, setUserProfile } from 'API/users';
import Loading from 'components/Loading';

const StyledTypography = styled(Typography)(() => ({
	fontSize: '18px!important',
	color: 'red',
}));

const StyledListItem = styled(ListItem)(() => ({
	fontSize: '1.0rem',
}));

const Profile = () => {
	const userToken = useContext(UserContext);
	const gdprNameMapping = (gdpr: boolean, fullGdpr: boolean, fullName: string, emoji: string = ''): string => {
		if (fullGdpr) {
			return '???';
		}

		if (gdpr) {
			return `${fullName.split(' ').map((v) => v.match(/./gu)).reduce((name, strChars) => `${name}${strChars![0].toUpperCase()}`, '')}${emoji ? `${emoji}` : ''}`;
		}

		return `${fullName}${emoji ? `${emoji}` : ''}`;
	};

	const [gdpr, setGdpr] = useState(false);
	const [fullGdpr, setFullGdpr] = useState(false);
	const [emoji, setEmoji] = useState('');
	const [emojiList, setEmojiList] = useState<string[]>([]);
	const [loaded, setLoaded] = useState(false);
	const [saving, setSaving] = useState(false);
	const [displayName, setDisplayName] = useState<string | null>(null);
	const sleepMs = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

	useEffect(() => {
		const fetchUserProfile = async () => {
			const data = await getUserProfile();
			const isPublic = data.profile.isPublic ?? false;
			const emoji = data.profile.emoji ?? '';
			const displayName = data.profile.displayName ?? '';
			const emojiList = data.emojiList ?? [];
			const isMysterious = data.profile.isMysterious ?? false;

			setGdpr(!isPublic);
			setFullGdpr(isMysterious);
			setEmoji(emoji);
			setDisplayName(displayName);
			setEmojiList(emojiList);
			setLoaded(true);
		};

		fetchUserProfile();
	}, []);

	const updateUserProfileFromState = useCallback(async () => {
		setSaving(true);

		await Promise.all([setUserProfile({ emoji: emoji || '', isPublic: !gdpr, isMysterious: Boolean(gdpr && fullGdpr) }), sleepMs(1000)]);
		setSaving(false);
	}, [gdpr, fullGdpr, emoji, setSaving]);

	const setGdprLogic = useCallback((gdpr: boolean) => {
		setGdpr(gdpr);
		if (!gdpr) {
			setFullGdpr(gdpr);
		}
	}, [setGdpr, setFullGdpr]);

	return (<div style={{ margin: '16px 0 0 16px', fontFamily: '\'Press Start 2P\'' }}>
		<p>Pozdravljen <span style={{ color: 'gold' }}>{userToken?.displayName}</span>, tukaj lahko urediš svoj profil.</p>
		{!loaded && <div style={{ marginLeft: '0px', paddingTop: 25 }}><Loading /></div> || <></>}
		{loaded && <><List style={{ paddingTop: '0' }}>
			<StyledTypography variant='h4'>Lestvica:</StyledTypography>
			<StyledListItem>* Prikaz: {gdprNameMapping(gdpr, fullGdpr, displayName!, emoji)}</StyledListItem>
			<StyledListItem>* Anonimiziraj me (<Checkbox checked={gdpr} onChange={(ev) => setGdprLogic(ev.target.checked)} style={{ paddingBottom: 14, color: 'red' }}></Checkbox>)</StyledListItem>
			{gdpr ? (<StyledListItem>&nbsp;&nbsp;~ še bolj na trenutni lestvici (<Checkbox checked={fullGdpr} onChange={(ev) => setFullGdpr(ev.target.checked)} style={{ paddingBottom: 14, color: 'red' }}></Checkbox>)</StyledListItem>) : <></>}
			{<StyledListItem>* Emoji
				(<div>
				<FormControl>
					<Select
						sx={{
							'.MuiOutlinedInput-notchedOutline': {
								borderColor: 'red',
							},
							'&:hover .MuiOutlinedInput-notchedOutline': {
								borderColor: 'red',
							},
							'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
								borderColor: 'red',
							},
						}}
						style={{ minWidth: 70, height: 30 }}
						displayEmpty={true}
						onChange={(e) => setEmoji(e.target.value)}
						input={<OutlinedInput />}
						MenuProps={{}}
						value={emoji}
						inputProps={{ 'aria-label': 'Without label' }}
					>
						<MenuItem value="">
							<em>Brez</em>
						</MenuItem>
						{emojiList.map((name) => (
							<MenuItem
								key={name}
								value={name}
								style={{}}
							>
								{name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>)
			</StyledListItem>}
		</List>
		<List style={{ paddingTop: '0' }}>
			<Button style={{ fontSize: 20 }} onClick={() => { updateUserProfileFromState(); }}>{saving ? '[💫]' : '[SHRANI]'}</Button>
		</List></>}

	</div>);
};

export default Profile;
