import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../App';

const Landing = () => {
	const user = useContext (UserContext);

	if (user) {
		return <Navigate replace to='/events' />;
	}

	return null;
};

export default Landing;
