export const getHalloweenVertexShader = () => `
varying vec2 vUv;
void main() {
    vUv = uv;
    gl_Position = vec4(position, 1.0);
}
`;

export const getHalloweenFragmentShader = () => `
uniform float iGlobalTime;
varying vec2 vUv;
uniform vec3 fogColor;
uniform vec3 background;
uniform float height;
uniform vec2 mousePosition;

#define PI 3.1415926535897932384626433832795
#define PI2 6.283185307179586476925286766559
#define TOP_BAR_HEIGHT 40.0

float noise(vec2 st) {
    return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

float smoothNoise(vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);
    vec2 u = f * f * (3.0 - 2.0 * f);

    return mix(mix(noise(i + vec2(0.0, 0.0)),
                   noise(i + vec2(1.0, 0.0)), u.x),
               mix(noise(i + vec2(0.0, 1.0)),
                   noise(i + vec2(1.0, 1.0)), u.x), u.y);
}

float generateFog(vec2 st) {
    float value = 0.0;
    float amplitude = 0.5;
    float frequency = 0.0;

    for (int i = 0; i < 6; i++) {
        value += amplitude * smoothNoise(st);
        st *= 2.0;
        amplitude *= 0.5;
    }

    return value;
}

float eye(vec2 center, float radius, float movement, float isGlowing)
{
    float moveRadius = radius * 0.75 + radius * movement;
    float radiusSmooth = 0.0; // radius / 5.0;

    vec2 center1 = vec2(center.x, center.y + moveRadius);
    vec2 center2 = vec2(center.x, center.y - moveRadius);

    float circle1 = 1.0 - smoothstep(radius - radiusSmooth, radius + radiusSmooth, distance(vUv, center1));
    float circle2 = 1.0 - smoothstep(radius - radiusSmooth, radius + radiusSmooth, distance(vUv, center2));
    float joined = smoothstep(max(0.95 - radiusSmooth, 0.9), min(0.95 + radiusSmooth, 1.0), (circle1 + circle2) / 2.0);

    vec2 pupilVector = normalize(mousePosition - center);
    float pupil = 1.0 - step(step(distance(vUv, center + pupilVector * radius / 4.0), radius / 4.0), 1.0 - isGlowing);

    return joined + pupil * step(1.0, joined);
}

float eyes(vec2 center, float size, float randOpen)
{
    float movement = sin((iGlobalTime + randOpen) / 60.0);
    vec2 center1 = vec2(center.x - size, center.y);
    vec2 center2 = vec2(center.x + size, center.y);
    float isGlowing = (1.0 - step(0.0, movement));
    float glowStrength = min(abs(movement * 0.5), 0.35);
    float glow1 = max(0.0, (0.35 + glowStrength - distance(vUv, center1) * 0.25 / size) * isGlowing);
    float glow2 = max(0.0, (0.35 + glowStrength - distance(vUv, center2) * 0.25 / size) * isGlowing);

    return (eye(center1, size, movement, isGlowing) + eye(center2, size, movement, isGlowing)) / 4.0 + glow1 + glow2;
}

float bubbles(float size, float speed, float blur, float time)
{
    vec2 ruv = vUv * size;
    vec2 id = ceil(ruv) + speed;

    ruv.y -= time * speed * 3.0 * max(0.2, noise(vec2(id.x)));
    vec2 guv = fract(ruv) - 0.5;

    ruv = ceil(ruv);
    float g = length(guv);

    float v = noise(ruv) * 0.5;
    v *= step(v, 0.3);

    float m = smoothstep(v, v - blur, g);

    v *= 0.85;
    m -= smoothstep(v, v - 0.1, g);

    float vf = v * 0.35;
    g = length(guv - vec2(vf, vf));

    float reflectionSize = v * 0.75;
    m += smoothstep(reflectionSize, 0.0, g) * 0.75;

    return m;
}

void main()
{
    vec2 st = vUv * 3.0;
    st.y -= iGlobalTime * 0.02; // Slow vertical movement
    float fog = generateFog(st); // Add time-based offset for moving effect

    vec3 color = mix(background, fogColor, fog);

    float factor = 3.0;
    float halfDiff = 1.0 / (2.0 * factor);
    float s = sin(vUv.x * PI2 * factor) * cos(vUv.y * PI2 * factor);
    float nX = round(vUv.x * factor - 0.5);
    float closestX = (nX + 0.5) / factor;
    float mY = round(vUv.y * factor - 0.5);
    float closestY = (mY + 0.5) / factor;
    float eyeSize = clamp(sin(closestX * 25617.784) * cos(closestY * 16379.454) / 50.0, 0.003, 0.015);
    float delay = sin(closestX * 11617.784) * cos(closestY * 13379.454) * 100.0;
    vec2 center = vec2(closestX, closestY);
    float offsetX = (noise(center) - 0.5) / (factor * 2.0);
    float offsetY = (noise(center) - 0.5) / (factor * 2.0);

    float eyes = eyes(vec2(closestX + offsetX, closestY + offsetY), eyeSize, delay);
    float fogRedColor = color.x + color.x * sin(iGlobalTime / 120.0 + PI) / 8.0 + color.x / 8.0;

    float size = 70.0;
    float speed = 75.0;
    float blur = 0.05;
    float m = bubbles(size, speed, blur, iGlobalTime / 3000.0) * fogRedColor;

    vec4 shader = vec4(mix(fogRedColor, 0.6, eyes), 0.4 - vUv.y / 1.5, color.z, 1);

    gl_FragColor = shader + m * smoothstep(height - TOP_BAR_HEIGHT, height, gl_FragCoord.y);
}
`;
