import { logger } from './logger';

export const shirtsErrorHandler = (e:any) => {
	const { errorCode } = e;

	switch (errorCode) {
		case 'NOT_AVAILABLE':
			logger.log('User not eligible to get shirt.');
			break;
		case 'INVALID_TIME':
			logger.log('You can`t claim a shirt at this time.');
			break;
		default:
			logger.error(e.message);
			break;
	}
};
