import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';

interface CountdownTimerProps {
	seconds: number;
	children?: React.ReactNode;
}

const CountdownTimer: React.FC<CountdownTimerProps> = (props: CountdownTimerProps) => {
	const { seconds: initialSeconds } = props;
	const [seconds, setSeconds] = useState(initialSeconds);

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds <= 0) {
				clearInterval(interval);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [seconds, initialSeconds]);

	return seconds > 0 ? <Typography style={{ color: 'red' }}>Upočasni pošiljanje rešitev. S pošiljanjem napačnih rešitev zapravljaš Božičkov čas 🎅. Počakaj vsaj <em>{Math.ceil(seconds)}</em> sekund.</Typography> : ((props as any).children || null);
};

export default CountdownTimer;
