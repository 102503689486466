import { Link } from 'react-router-dom';
import { Link as MUILink } from '@mui/material';
import { styled } from '@mui/system';
import { PRIMARY } from '../../styles/AOCTheme';
import { useLocation } from 'react-router-dom';

const StyledMUILink = styled(MUILink)(({ theme }) => ({
	lineHeight: '1.43',
	[theme.breakpoints.down('sm')]: {
		fontSize: '0.9rem',
	},
}));

interface IAOCHeaderLink {
	path: string;
	name: string;
	eventId?: string;
	external?: boolean;
}

const AOCHeaderLink: React.FC<IAOCHeaderLink> = (props) => {
	const { path, name, external } = props;
	const location = useLocation();

	const commonProps = {
		onClick: () => { if (external) window.open(path); },
		color: 'primary' as const,
		sx: { textShadow: location.pathname === path ? `0 0 5px ${PRIMARY}` : '' },
		...(external ? { href: path } : { to: path, component: Link }),
	};

	return <StyledMUILink {...commonProps}>[{name}]</StyledMUILink>;
};

export default AOCHeaderLink;
