import firebaseConfig from './runtime-config';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';

export const Firebase = initializeApp(firebaseConfig.firebase);

export const auth = getAuth(Firebase);
export const messaging = getMessaging(Firebase);

export const getMessagingRegistrationToken = (): Promise<string> => getToken(messaging, { vapidKey: firebaseConfig.vapidKey });

export const baseURL = firebaseConfig.baseURL;

// initialize background message processing.
onMessage(messaging, (payload) => {
    type NotificationDataType = {
        type: string;
        title: string;
        icon: string;
        body: string;
        taskUrl: string;
    };
    const { title, body, icon, taskUrl } = payload.data as NotificationDataType;
    const notification = new Notification(title, { body, icon, requireInteraction: true });

    notification.onclick = () => {
    	notification.close();
    	window.location.href = taskUrl;
    };
});
