import { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { MAX_CONTAINER_WIDTH_PX } from '../../styles/AOCTheme';
import { Typography } from '@mui/material';
import TaskPartDetails from '../TaskPartDetails';
import { submitTaskPartSolution, getTaskInput, getTaskHistory } from 'API/tasks';
import AOCError from 'API/AOCError';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectTask, fetchTask, showHideInfoDialog, selectTasksLoading } from 'state/tasks/tasksSlice';
import Loading from 'components/Loading';
import { ParamTypes } from 'types/ParamTypes';

const StyledDiv = styled('div')(() => ({
	padding: '32px 16px',
	maxWidth: MAX_CONTAINER_WIDTH_PX,
}));

const TaskDetails: React.FC = () => {
	const { eventId, taskId } = useParams() as ParamTypes;
	const task = useAppSelector((state) => selectTask(state, taskId));
	const isLoading = useAppSelector(selectTasksLoading);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchTask({ eventId, taskId }));
	}, [dispatch, eventId, taskId]);

	const handleFetchTaskInput = useCallback(async (partNumber: string | number, { download = false } = {}): Promise<string> => {
		const response = await getTaskInput(eventId, taskId, partNumber);
		const text = await response.clone().text();

		if (download) {
			const blob = await response.clone().blob();
			const url = window.URL.createObjectURL(
				new Blob([blob]),
			);
			const link = document.createElement('a');

			link.href = url;
			link.setAttribute('download', `input-${Number(partNumber) + 1}.txt`);
			document.body.appendChild(link);
			link.click();
			link.parentNode?.removeChild(link);
		}

		return text;
	}, [eventId, taskId]);

	const handleDownloadPartHistory = useCallback(async (partNumber: string | number): Promise<void> => {
		const jsonData = await getTaskHistory(eventId, taskId, partNumber);
		const url = window.URL.createObjectURL(
			new Blob([jsonData.text], { type: 'text/plain' }),
		);
		const link = document.createElement('a');

		link.href = url;
		link.setAttribute('download', `history-${eventId}-${taskId}-${Number(partNumber) + 1}.txt`);
		document.body.appendChild(link);
		link.click();
		link.parentNode?.removeChild(link);
	}, [eventId, taskId]);

	const handleSubmitAnswer = useCallback(async (partNumber: number, answer: string | Uint8Array) => {
		try {
			const { solutionAccepted } = await submitTaskPartSolution(eventId, taskId, partNumber, answer);

			const message = solutionAccepted ? '🎅 je odobril tvojo rešitev! ✅' : '🎅 je poslal tvojo rešitev na seznam porednih! Popravi svojo rešitev in poskusi znova. ❌';

			dispatch(showHideInfoDialog({ title: '', message }));

			dispatch(fetchTask({ eventId, taskId }));
		} catch (e) {
			if (e instanceof AOCError) {
				const { message, errorCode } = e;

				if (errorCode === 'RATE_LIMIT_REACHED') {
					dispatch(showHideInfoDialog({ title: 'Napaka', message: 'Ho ho ho! Ne bodi poreden/poredna in počakaj preden ponovno pošlješ rešitev! 🎅' }));

					return;
				}

				dispatch(showHideInfoDialog({ title: 'Napaka', message }));
			}
		}
	}, [dispatch, eventId, taskId]);

	return <StyledDiv>
		{/* Show loader. */}
		{!task && isLoading && <Loading />}

		{task && <>
			{/* Show task name. */}
			<Typography variant='h4' style={{ marginBottom: 32, color: 'white' }}>{task.name}</Typography>

			{/* List task unlocked parts. */}
			{task.unlockedParts.map((part, i, array) => <TaskPartDetails
				key={`${task.id}_${part.partNumber}_${part.rateLimitWaitSeconds}`}
				taskPart={part}
				eventId={eventId}
				taskId={taskId}
				isLastPart={(i === array.length - 1) && task.completed}
				onSubmitAnswer={handleSubmitAnswer}
				onGetPuzzleInput={handleFetchTaskInput}
				onDownloadPartHistory={handleDownloadPartHistory}
				additionalData={{ retrySeconds: part.rateLimitWaitSeconds }}
				taskType={task.type}
				evaluatorFunction={task.evaluatorFunction} />)}
		</>}
	</StyledDiv>;
};

export default TaskDetails;
