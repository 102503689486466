import { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { getHalloweenFragmentShader, getHalloweenVertexShader } from './shaders';
import { createBackgroundState } from './background-state';
import { RainDrop, produceDrops } from './rain-drop';
import { AnimationFrameFpsLock } from 'utils/animation-frame-fps-lock';
import { initializeWitch } from './witch';

const zenModeWindowVariable = '$AOC_CANVAS_ZEN_MODE';

export const setBackgroundZenMode = (zenMode: boolean) => {
	(window as any)[zenModeWindowVariable] = zenMode;
};

export const getBackgroundZenMode = (): boolean => {
	return (window as any)[zenModeWindowVariable] ?? false;
};

const dirSqrt = (v: number) => Math.sign(v) * Math.sqrt(Math.abs(v));

const windowEventListenerCleanupFactory = <K extends keyof WindowEventMap>(type: K, callback: (this: Window, ev: WindowEventMap[K]) => void) => {
	window.addEventListener(type, callback);

	return () => window.removeEventListener(type, callback);
};

const RainBackground = () => {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const backgroundAnimationState = useRef(createBackgroundState());

	useEffect(() => {
		const raindropProduceMaxMin = 25;
		let rainDrops: RainDrop[] = [];
		let nextRaindropMinProduce = raindropProduceMaxMin;

		function compileBackgroundMesh() {
			return new THREE.Mesh(
				new THREE.PlaneGeometry(2, 2, 1, 1),
				new THREE.ShaderMaterial({
					uniforms: backgroundAnimationState.current.uniforms,
					vertexShader: getHalloweenVertexShader(),
					fragmentShader: getHalloweenFragmentShader(),
				}));
		}

		function handleRaindropsAnimation(canvas: HTMLCanvasElement) {
			const state = backgroundAnimationState.current;
			const scene = state.renderWorld?.scene;

			if (!scene) {
				return;
			}

			const minDropY = Math.min(...rainDrops.map((drop) => drop.y));
			const size = 8;
			const minSpeed = 3;
			const maxSpeed = 10;
			// drop distance from mouse pointer constraint
			const distT = 150;
			const enableRainWobble = false;
			const enableRainRotate = false;

			if (!rainDrops.length || minDropY > nextRaindropMinProduce || state.konamiCode.activated) {
				nextRaindropMinProduce = Math.random() * raindropProduceMaxMin;
				let dropBoostFactor = 1;

				if (state.konamiCode.activated) {
					state.konamiCode.activated = false;
					dropBoostFactor = 50;
				}
				const newDrops = produceDrops(canvas.width, dropBoostFactor);

				scene.add(...newDrops.map((f) => f.dropMesh));
				rainDrops.push(...newDrops);
			}

			if (state.mouseForceCalculated) {
				state.mouseForceCalculated = false;
				// find drops in range of mouse pointer, then apply scalar velocity based on distance from mouse pointer
				rainDrops.forEach((rd) => {
					const dx = state.mouseX - rd.x;
					const dy = state.mouseY - rd.y;
					const dist = Math.sqrt(dx ** 2 + dy ** 2);
					const speed = Math.max(minSpeed, rd.speedRng * maxSpeed);
					const dSpeed = speed - minSpeed;
					const fSpeed = Math.max(dSpeed / (maxSpeed - minSpeed), 0.3);
					// slower (smaller) the drop, the less powerful should the force be (fSpeed *)
					// exponential scaling to have lesser impact the bigger the distance gets.
					// TODO maybe not...
					const distForceFraction = fSpeed * Math.pow(1 - Math.min(dist / distT, 1), 2);
					const inDist = dist <= distT;

					if (!inDist) {
						return;
					}

					rd.vx += state.mouseForceX * distForceFraction;
					rd.vy += state.mouseForceY * distForceFraction;
				});
			}

			for (const rainDrop of rainDrops) {
				const speed = Math.max(minSpeed, rainDrop.speedRng * maxSpeed);
				const dSpeed = speed - minSpeed;
				const fSpeed = dSpeed / (maxSpeed - minSpeed);
				const fSize = Math.max(2, fSpeed * size);
				const rainForce = {
					y: speed,
					x: rainDrop.dirRng - 2 + (enableRainWobble ? 1 : 0) * Math.sin(rainDrop.y / 10 + rainDrop.speedRng * 10) * 0.25,
				};

				// Map calculated values for view (PlaneGeometry three.js)
				rainDrop.dropMesh.position.x = rainDrop.x;
				rainDrop.dropMesh.position.y = -rainDrop.y;
				rainDrop.dropMesh.scale.x = fSize;
				rainDrop.dropMesh.scale.y = fSize;
				rainDrop.dropMesh.scale.z = fSize;
				rainDrop.dropMesh.rotation.z = -Math.PI / 8;
				if (enableRainRotate) {
					rainDrop.dropMesh.rotation.z += -dirSqrt(rainDrop.dirRng) * rainDrop.speedRng * 0.1;
				}
				rainDrop.y += rainForce.y + rainDrop.vy;
				rainDrop.x += rainForce.x + rainDrop.vx;

				// damping of the velocity, then terminate at specific threshold so it doesn't damp infinitely.
				rainDrop.vx *= 0.95;
				rainDrop.vy *= 0.95;
				if (Math.abs(rainDrop.vx) < 0.01) rainDrop.vx = 0;
				if (Math.abs(rainDrop.vy) < 0.01) rainDrop.vy = 0;

			}
			const keepDropInSceneConditional = (rainDrop: RainDrop): boolean => rainDrop.y < canvas.height + 25;

			scene.remove(...rainDrops.filter((rainDrop) => !keepDropInSceneConditional(rainDrop)).map((f) => f.dropMesh));
			rainDrops = rainDrops.filter((rainDrop) => keepDropInSceneConditional(rainDrop));
		}

		let witchResolvedState: Awaited<ReturnType<typeof initializeWitch>> | undefined;

		const mouseClickCleanup = windowEventListenerCleanupFactory('click', (e: MouseEvent) => {
			witchResolvedState?.onClick(e);
		});

		let dropsVisible = true;
		let witchVisible = true;
		const animation = new AnimationFrameFpsLock({
			maxFps: 60,
			onRender: () => {
				const zenMode = getBackgroundZenMode();

				const canvas = canvasRef.current;

				if (!canvas) {
					return;
				}

				const state = backgroundAnimationState.current;

				if (!state.renderWorld) {
					const renderer = new THREE.WebGLRenderer({ canvas });
					const camera = new THREE.OrthographicCamera();
					const scene = new THREE.Scene();

					camera.left = 0;
					camera.top = 0;
					camera.near = 0.1;
					camera.far = 1000;
					camera.position.z = 100;
					scene.add(compileBackgroundMesh());
					state.renderWorld = {
						camera,
						renderer,
						scene,
					};
					initializeWitch(scene, camera).then((result) => witchResolvedState = result);
				}

				const { renderer, camera, scene } = state.renderWorld;

				canvas.style.width = '100%';
				canvas.style.height = '100%';
				canvas.width = canvas.offsetWidth;
				canvas.height = canvas.offsetHeight;

				// force camera to match canvas dimensions, then update its aspect ratio and position
				camera.right = canvas.width;
				camera.bottom = -canvas.height;
				camera.updateProjectionMatrix();

				if (!zenMode) {
					state.uniforms.iGlobalTime.value += 1;
				}

				state.uniforms.height.value = canvas.height;
				state.uniforms.width.value = canvas.width;

				renderer.setSize(canvas.width, canvas.height);
				if (!zenMode) {
					if (!dropsVisible) {
						rainDrops.map((f) => f.dropMesh.visible = true);
						dropsVisible = true;
					}

					if (witchResolvedState && !witchVisible) {
						witchResolvedState.setVisibility(true);
						witchVisible = true;
					}

					if (witchResolvedState) {
						witchResolvedState.onUpdate();
					}
					handleRaindropsAnimation(canvas);
				} else {
					if (dropsVisible) {
						rainDrops.map((f) => f.dropMesh.visible = false);
						dropsVisible = false;
					}

					if (witchResolvedState && witchVisible) {
						witchResolvedState.setVisibility(false);
						witchVisible = false;
					}
				}
				renderer.render(scene, camera);
			},
		});

		animation.start();

		return () => {
			animation.destroy();
			mouseClickCleanup();
		};
	}, [backgroundAnimationState]);

	useEffect(() => {
		const state = backgroundAnimationState.current;
		const mouseMoveCleanup = windowEventListenerCleanupFactory('mousemove', (e: MouseEvent) => {
			state.setMousePos(e.clientX, e.clientY);
		});
		const keyDownCleanup = windowEventListenerCleanupFactory('keydown', (e: KeyboardEvent) => {
			state.konamiCode.addKey(e.key.toLowerCase());
		});

		return () => {
			mouseMoveCleanup();
			keyDownCleanup();
		};

	}, [backgroundAnimationState]);

	return (<canvas ref={canvasRef}></canvas>);
};

export default RainBackground;
