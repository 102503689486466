import { MIDNIGHT_SKY_BACKGROUND_COLOR } from 'styles/AOCTheme';
import * as THREE from 'three';

export const createBackgroundState = () => ({
	uniforms: {
		background: { type: 'vec3', value: new THREE.Color(MIDNIGHT_SKY_BACKGROUND_COLOR).convertLinearToSRGB() },
		fogColor: { type: 'vec3', value: new THREE.Color('#777777').convertLinearToSRGB() },
		mousePosition: { type: 'vec2', value: new THREE.Vector2(0, 0) },
		height: { type: 'f', value: 1 },
		width: { type: 'f', value: 1 },
		iGlobalTime: { type: 'f', value: 0.1 },
	},
	renderWorld: null as { renderer: THREE.WebGLRenderer; scene: THREE.Scene; camera: THREE.OrthographicCamera } | null,
	mouseForceX: 0,
	mouseForceY: 0,
	mouseX: 0,
	mouseY: 0,
	steps: [] as { x: number; y: number; ts: number }[],
	mouseForceStepsReq: 2,
	mouseForceCalculated: false,
	setMousePos(x: number, y: number) {
		this.mouseX = x;
		this.mouseY = y;
		this.uniforms.mousePosition.value = new THREE.Vector2(x / this.uniforms.width.value, (this.uniforms.height.value - y) / this.uniforms.height.value);
		this.steps.push({ x, y, ts: Date.now() });
		if (this.steps.length > this.mouseForceStepsReq) this.steps.shift();
		if (this.steps.length == this.mouseForceStepsReq) {
			const lastIdx = this.mouseForceStepsReq - 1;
			const dt = Math.max(this.steps[lastIdx].ts - this.steps[0].ts, 1);
			const dx = (this.steps[lastIdx].x - this.steps[0].x) / dt;
			const dy = (this.steps[lastIdx].y - this.steps[0].y) / dt;

			this.mouseForceCalculated = true;
			this.setMouseForce(dx, dy);
		}

		return null;
	},
	setMouseForce(x: number, y: number) {
		this.mouseForceX = x;
		this.mouseForceY = y;
	},
	konamiCode: {
		unlockSeq: ['arrowup', 'arrowup', 'arrowdown', 'arrowdown', 'arrowleft', 'arrowright', 'arrowleft', 'arrowright', 'b', 'a', 'enter'],
		streamSeq: [] as string[],
		activated: false,
		addKey(key: string) {
			this.streamSeq.push(key);
			const unlockSeqStr = this.unlockSeq.map((l) => `[${l}]`).join('');
			const streamSeqStr = this.streamSeq.map((l) => `[${l}]`).join('');

			if (!unlockSeqStr.startsWith(streamSeqStr)) {
				this.streamSeq = [];
			} else if (unlockSeqStr === streamSeqStr) {
				this.streamSeq = [];
				this.activated = true;
			}
		},
	},
});
