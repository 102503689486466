import { RAINDROP_COLOR } from 'styles/AOCTheme';
import * as THREE from 'three';

export type RainDrop = {
	dropMesh: THREE.Mesh;
	x: number;
	y: number;
	speedRng: number;
	dirRng: number;
	vx: number;
	vy: number;
};

export const produceDrops = (width: number, boostFactor: number = 1): RainDrop[] => {
	const wPad = 150;

	width = width + 2 * wPad;

	const geometry = new THREE.PlaneGeometry(1, 2);
	const yOffset = 150;
	const xDist = 25;
	const count = Math.ceil((width / xDist) * boostFactor);

	return new Array(count).fill(null).map((_, i) => {
		const dropMesh = new THREE.Mesh(geometry);

		// Three.js type definitions were severely wrong with this one.
		(dropMesh.material as unknown as { color: { set(color: string): void } }).color.set(RAINDROP_COLOR);

		return {
			dropMesh,
			x: wPad + ((i * xDist) % (width + wPad)),
			y: -yOffset * Math.random(),
			speedRng: Math.random(),
			dirRng: Math.random() * 2 - 1,
			vx: 0,
			vy: 0,
		};
	});
};
