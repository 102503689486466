import { styled } from '@mui/system';
import { Box, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LeaderboardAggregation, LeaderboardUser } from 'types/Leaderboard';
import { useDispatch } from 'react-redux';
import { showHideInfoDialog } from 'state/tasks/tasksSlice';
import Loading from 'components/Loading';
import { useParams } from 'react-router-dom';
import { getLeaderboardWithSharedToken, getTaskLeaderboard } from 'API/leaderboard';
import { ParamTypes } from 'types/ParamTypes';
import { CANDYCANE_RED } from 'styles/AOCTheme';
import { getLeaderboardTitle } from 'utils/getLeaderboardTitle';
import { getCurrentSeasonInfo } from '../Background/season';

const CONTAINER_PADDING = 16;

const prefix = 'TaskLeaderboard';
const classes = {
	title: `${prefix}-title`,
	leaderboardHeader: `${prefix}-leaderboardHeader`,
	leaderboardContainer: `${prefix}-leaderboardContainer`,
	leaderboardOptionsPrimary: `${prefix}-leaderboardOptionsPrimary`,
	leaderboardOptions: `${prefix}-leaderboardOptions`,
};

const StyledDiv = styled('div')(() => ({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	height: 'max-content',
	flexDirection: 'column',
	overflow: 'hidden',
}));

const StyledBox = styled(Box)(({ theme }) => ({
	[`&.${classes.leaderboardHeader}`]: {
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)',
		gridRowGap: '16px',
		width: '100%',
		placeItems: 'center',
		zIndex: 100,
		'& > *': {
			fontFamily: '\'Press Start 2P\', \'cursive\'',
			[theme.breakpoints.down('sm')]: {
				fontSize: '1rem !important',
			},
		},
	},
	[`&.${classes.leaderboardContainer}`]: {
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)',
		gridRowGap: '16px',
		width: '100%',
		placeItems: 'center',
		padding: `${CONTAINER_PADDING}px 0`,
		'& > *': {
			fontFamily: '\'Press Start 2P\', \'cursive\'',
		},
	},
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
	[`&.${classes.title}`]: {
		margin: '3rem 0 2rem 0 !important',
		textAlign: 'center',
		textTransform: 'uppercase',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
	},
	[`&.${classes.leaderboardOptionsPrimary}`]: {
		color: 'gold',
		cursor: 'pointer',
		'&:hover': {
			textShadow: '0 0 5px gold',
		},
	},
	[`&.${classes.leaderboardOptions}`]: {
		color: CANDYCANE_RED,
		cursor: 'pointer',
		'&:hover': {
			textShadow: `0 0 5px ${CANDYCANE_RED}`,
		},
	},
}));

export const getTypography = (data: string | number, color: string, padEmoji?: boolean) => {
	// i'm sorry for whomever gets to fix this code. I had OCD with emojis and this was
	// my best effort / attempt to "inline + align" them nicely with leaderboard.
	const splitTextEmoji = (str: string) => {
		const [text, ...emojiArray] = str.split(/(\p{Emoji})/u);

		return { text, emoji: emojiArray.join('') };
	};
	const hiddenCenter = <span style={{ visibility: 'hidden', verticalAlign: 'text-bottom' }}>🎆</span>;

	if (padEmoji) {
		const { text, emoji } = splitTextEmoji(data as string);
		const ignoreEndPadding = (data as string).includes(' ') || emoji.length;

		return <Typography variant='h5' sx={{ color }}>{hiddenCenter}{text}{(ignoreEndPadding ? <span style={{ verticalAlign: 'text-bottom' }}>{emoji}</span> : hiddenCenter)}</Typography>;
	}

	return <Typography variant='h5' sx={{ color }}>{hiddenCenter}{data}{hiddenCenter}</Typography>;
};

interface ITaskLeaderboardProps {
	isTv?: boolean;
}
const TaskLeaderboard: React.FC<ITaskLeaderboardProps> = (props) => {
	const [eventName, setEventName] = useState<string>('');
	const { isTv = false } = props;
	const dispatch = useDispatch();
	const [leaderBoardData, setLeaderBoardData] = useState<LeaderboardUser[]>([]);
	const [leaderboardAggregationData, setLeaderBoardAggregationData] = useState<LeaderboardAggregation>({});
	const [baseLeaderboardData, setBaseLeaderboardData] = useState<any>({});
	const [selectedTab, setSelectedTab] = useState<string>('general');
	const [hasScore, setHasScore] = useState<boolean>(false);
	const [isLoading, setLoading] = useState(false);

	;
	const { eventId, taskId } = useParams() as ParamTypes;
	const INTERVAL_DURATION = 300000;

	const COLORS = getCurrentSeasonInfo().letterColorSequence;

	useEffect(() => {
		const fetchTaskLeaderboard = async () => {
			setLoading(true);
			const params = new URLSearchParams(window.location.search);

			const sharedToken = params.get('token');

			let data;

			try {
				if (sharedToken) {
					data = await getLeaderboardWithSharedToken(eventId, sharedToken);
				} else {
					data = await getTaskLeaderboard(eventId, taskId);
				}
				const { eventName, leaderboardData, hasScore, achievementsAggregation } = data;

				setBaseLeaderboardData(data);
				setEventName(eventName);
				setLeaderBoardData(leaderboardData);
				setLeaderBoardAggregationData(achievementsAggregation);
				setHasScore(hasScore);
			} catch (e) {
				if (e instanceof Error) {
					if (!isTv) {
						dispatch(showHideInfoDialog({ title: 'Napaka', message: e.message }));
					} else {
						// eslint-disable-next-line no-console
						console.log(e);
					}
				}
			} finally {
				setLoading(false);
			}
		};

		fetchTaskLeaderboard();
		const interval = setInterval(fetchTaskLeaderboard, INTERVAL_DURATION);

		return () => clearInterval(interval);
	}, [dispatch, eventId, isTv, taskId]);

	const isGeneralTabSelected = selectedTab === 'general';
	const aggregationLeaderboardEntries = Object.entries(leaderboardAggregationData);
	const boldify = (data: string, bold: boolean) => bold ? <b>{data}</b> : data;

	const numOfHelpers: number = baseLeaderboardData.eventParticipants ?? 0;
	const generalTabValueName = hasScore ? 'rezultat' : 'točke';

	const pointsScoreMapper = (value: number) => hasScore && !Number.isInteger(value) ? value.toFixed(3) : value;
	const pointsScoreMapperNullSafe = (value: number | null) => value != null ? pointsScoreMapper(value) : null;

	const rankingName: null | string = isGeneralTabSelected
		? generalTabValueName
		: baseLeaderboardData.userRanking?.achievementTasks?.[taskId]?.[selectedTab]?.name ?? null;
	const userRanking: null | number = isGeneralTabSelected
		? baseLeaderboardData.userRanking?.tasks?.[taskId]?.rank ?? null
		: baseLeaderboardData.userRanking?.achievementTasks?.[taskId]?.[selectedTab]?.rank ?? null;
	const userRankingValue: null | string = isGeneralTabSelected
		? hasScore
			? pointsScoreMapperNullSafe(baseLeaderboardData.userRanking?.tasks?.[taskId]?.score ?? null)
			: baseLeaderboardData.userRanking?.tasks?.[taskId]?.points ?? null
		: baseLeaderboardData.userRanking?.achievementTasks?.[taskId]?.[selectedTab]?.value ?? null;
	const leaderboardUpdatedAt: null | string = baseLeaderboardData?.calculatedAt ?? null;
	const showRanking = userRanking && userRankingValue;

	const buttonAnchorStyle = { textDecoration: 'none', color: 'unset', textShadow: 'inherit' };

	return (<>
		{!isLoading && leaderboardUpdatedAt && <p style={{ fontFamily: '\'Press Start 2P\'', fontSize: '0.5rem', textAlign: 'center', marginBottom: 5 }}>Zadnja avtomatska posodobitev: {new Date(leaderboardUpdatedAt).toLocaleString('sl-SI', { timeZone: 'UTC' })} (UTC)</p>}
		{!isLoading && <Box display={'flex'} justifyContent={'center'}>
			{<StyledTypography onClick={() => setSelectedTab('general')} className={classes.leaderboardOptionsPrimary} style={{ color: 'gold', textShadow: isGeneralTabSelected ? '0 0 10px gold' : '' }} variant='subtitle1'><a style={buttonAnchorStyle}>{boldify('[🏆Generalna lestvica🏆]', isGeneralTabSelected)}</a></StyledTypography>}
			{aggregationLeaderboardEntries.map(([key, values]) => {
				const title = getLeaderboardTitle(key) ?? '???';
				const isSelected = selectedTab === key;
				const enabled = values?.length > 0;
				const isOptimum = key === 'bestOptimizer';
				const disabledText = isOptimum ? 'Lestvica trenutno ni na voljo, saj se še ni našel Božičkov pomočnik, ki bi rešil optimizacijsko nalogo.' : 'Lestvica trenutno ni na voljo, saj še noben Božičkov pomagač ni rešil vseh odprtih nalog (glej pravila). Če želiš, lahko vidiš izbrano lokalno lestvico pri seznamu nalog.';

				if (isOptimum && !hasScore) {
					return;
				}

				return <Tooltip key={key} title={enabled ? '' : disabledText} >
					<StyledTypography sx={{ ...(enabled ? {} : { color: 'rgba(0, 0, 0, 0.38)!important' }) }} key={key} style={{ textShadow: selectedTab === key ? `0 0 10px ${CANDYCANE_RED}` : '' }} onClick={() => enabled ? setSelectedTab(key) : null} className={classes.leaderboardOptions} variant='subtitle1'><a style={buttonAnchorStyle}>{boldify(`[${title}]`, isSelected)}</a></StyledTypography>
				</Tooltip>;
			})}
		</Box>}
		{eventName && <StyledTypography variant='h2' className={classes.title}>{eventName} - Lokalna lestvica</StyledTypography>}
		{!isLoading && showRanking && <Typography variant='h4' style={{ fontSize: '0.9rem', textAlign: 'center', marginBottom: '3rem' }}>zavzemaš <span style={{ color: 'gold', fontWeight: 'bold' }}>{userRanking}.</span> mesto na lestvici ({rankingName}: <span style={{ color: 'gold', fontWeight: 'bold' }}>{userRankingValue}</span>)</Typography>}
		{!isLoading && <Typography variant='h4' style={{ fontSize: '0.9rem', textAlign: 'center', marginBottom: '3rem' }}>Število Božičkovih pomočnikov: {numOfHelpers}</Typography>}
		<StyledBox component='div' className={classes.leaderboardHeader} >
			{isLoading && <div style={{
				position: 'absolute',
				left: '50%',
				top: '50%',
				transform: 'translate(-50%, -50%)',
			}}><Loading /></div>}
			{!isLoading && <>
				<Typography variant='h4' style={{ color: 'rgba(255,221,0, 0.8)' }}>mesto</Typography>
				<Typography variant='h4' style={{ color: 'rgba(255,221,0, 0.8)' }}>{isGeneralTabSelected ? generalTabValueName : leaderboardAggregationData[selectedTab]?.[0]?.columnName ?? generalTabValueName}</Typography>
				<Typography variant='h4' style={{ color: 'rgba(255,221,0, 0.8)' }}>ime</Typography>
			</>}
		</StyledBox>
		<StyledDiv>
			<StyledBox component='div' id='usersContainer' className={classes.leaderboardContainer}>
				{isGeneralTabSelected ? leaderBoardData.map((user, index) => {
					const color = COLORS[index % COLORS.length];

					return (<React.Fragment key={index}>
						{user.isBold ? <em>{getTypography(user.rank, color)}</em> : getTypography(user.rank, color)}
						{user.isBold ? <em>{getTypography(pointsScoreMapper(user.score), color)}</em> : getTypography(pointsScoreMapper(user.score), color)}
						{user.isBold ? <em>{getTypography(user.displayName, color, true)}</em> : getTypography(user.displayName, color, true)}
					</React.Fragment>);
				}) : leaderboardAggregationData[selectedTab]?.map((item, index) => {
					const color = COLORS[index % COLORS.length];

					return (<React.Fragment key={index}>
						{item.isBold ? <em>{getTypography(item.rank, color)}</em> : getTypography(item.rank, color)}
						{item.isBold ? <em>{getTypography(item.columnValue, color)}</em> : getTypography(item.columnValue, color)}
						{item.isBold ? <em>{getTypography(item.displayName, color, true)}</em> : getTypography(item.displayName, color, true)}
					</React.Fragment>);
				})}
			</StyledBox>
		</StyledDiv>
	</>
	);
};

export default TaskLeaderboard;
