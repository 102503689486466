/**
 * DISCLAIMER: I'm truly sorry for what you're about to witness here.
 * This code is the unfortunate result of a rushed attempt to port Santa's magical code
 * from an HTML & THREE.js prototype into React with TypeScript.
 * It’s not pretty, it’s not elegant, and it’s definitely not optimized.
 * But hey, deadlines don’t wait, and neither does Christmas! 🎅
 *
 * Please bear with this "creative" mess, and may you find peace in knowing
 * that I didn't have enough time to make it less 💩.
 *
 * Proceed at your own risk. You've been warned.
 * - Dominik
 */

import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';

const BASE_SCALE = 40;
let SCALE = BASE_SCALE + 20;
const animationSpeedUpdate = 0.011;
const reindeerSpeed = 0.1;
const checkIfInEventsRegex = /events\/.+/;

export async function initializeSantaSleighs(mainScene: THREE.Scene, camera: THREE.OrthographicCamera) {
    type ReinDeerType = Awaited<ReturnType<typeof createReindeer>>;
    const GROUP = new THREE.Group();
    const globalModelLoader = new GLTFLoader();
    const modelBufferPromises: { [url: string]: Promise<ArrayBuffer> } = {};

    async function globalLoadModel(url: string): Promise<GLTF> {
    	const bufferPromise = modelBufferPromises[url] ??= fetch(url).then((response) => response.arrayBuffer());

    	return await globalModelLoader.parseAsync(await bufferPromise, '');
    }

    async function loadAndInitializeModel(url: string) {
    	const gltf = await globalLoadModel(url);
    	const model = gltf.scene;
    	const mixer = new THREE.AnimationMixer(model);

    	gltf.animations.forEach((clip) => {
    		mixer.clipAction(clip).play();
    	});

    	return { model, mixer, gltf };
    }

    function getWorldAxisDirection(mesh: THREE.Mesh | THREE.Object3D, axisVector3: THREE.Vector3) {
    	const localXAxis = axisVector3.clone();

    	const worldMatrix = new THREE.Matrix4();

    	worldMatrix.copy(mesh.matrixWorld);

    	worldMatrix.setPosition(0, 0, 0);

    	const worldXAxis = localXAxis.applyMatrix4(worldMatrix);

    	return worldXAxis;
    }

    function createRopeCylinder() {
    	const geometry = new THREE.CylinderGeometry(0.02, 0.02, 10, 8);
    	const material = new THREE.MeshBasicMaterial({ color: 0x221909 });
    	const cylinder = new THREE.Mesh(geometry, material);

    	return cylinder;
    }

    async function createSantaSleight() {
    	const santaSleighUrl = 'https://firebasestorage.googleapis.com/v0/b/inovait-advent-of-code.appspot.com/o/AOC_STATIC%2Fsleigh_v4.glb?alt=media&token=f19dd671-2812-4aa9-8b81-a5bcbf229d52';
    	const { gltf, mixer } = await loadAndInitializeModel(santaSleighUrl);
    	const sleight = gltf.scene;
    	const pivot = new THREE.Object3D();

    	pivot.add(sleight);
    	sleight.position.set(0, 0, 3);
    	const ropeCylinderHands = createRopeCylinder();

    	const LR = { L: null as (THREE.Object3D | null), R: null as (THREE.Object3D | null) };
    	const mapper: { [key: string]: Function } = { 'Hand_R': (v: any) => LR.R = v, 'Hand_L': (v: any) => LR.L = v };

    	sleight.traverse((node) => {
    		if ((node as any).isBone) {
    			const fn = mapper[node.name];

    			if (fn) {
    				fn(node);
    			}
    		}
    	});
    	const mockReindeerRopePointsAsHands = {
    		isRudolf() { return false; },
    		getReindeersLRNodes() {
    			return {
    				// left hand
    				reindeer1: { R: LR.L, L: LR.L },
    				// right hand
    				reindeer2: { R: LR.R, L: LR.R },
    			};
    		},
    	};

    	return {
    		_hoverAnimIdx: 0,
    		_angle: 0,
    		_rotation: 0,
    		position: { x: 0, y: 0, z: 0 },

    		getWorldPosition() {
    			return pivot.getWorldPosition(new THREE.Vector3());
    		},

    		getReindeerRopePointsMock() {
    			return mockReindeerRopePointsAsHands;
    		},
    		setRotation(rotation: number) {
    			this._rotation = rotation;
    		},
    		setAngle(angle: number) {
    			this._angle = angle;
    		},
    		addToScene(scene: THREE.Scene | THREE.Group) {
    			scene.add(pivot);
    			scene.add(ropeCylinderHands);
    		},
    		positionRopeBothHands() {
    			const L = LR.L!.getWorldPosition(new THREE.Vector3()).multiplyScalar(1 / SCALE);
    			const R = LR.R!.getWorldPosition(new THREE.Vector3()).multiplyScalar(1 / SCALE);

    			updateCylinderBetweenPoints(ropeCylinderHands, L, R);
    		},
    		update() {
    			mixer.update(animationSpeedUpdate);
    			this._hoverAnimIdx += 0.1;
    			pivot.position.x = this.position.x;
    			pivot.position.y = this.position.y;
    			pivot.position.z = this.position.z;

    			const zRotation = this._angle;
    			const xRotation = this._rotation;

    			const yQuaternion = new THREE.Quaternion();

    			yQuaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / 2);
    			const zQuaternion = new THREE.Quaternion();

    			zQuaternion.setFromAxisAngle(new THREE.Vector3(0, 0, 1), zRotation);
    			const xQuaternion = new THREE.Quaternion();

    			xQuaternion.setFromAxisAngle(new THREE.Vector3(1, 0, 0), xRotation);
    			pivot.quaternion.copy(zQuaternion).multiply(xQuaternion).multiply(yQuaternion);

    			const degreesHoverMaxZ = 20;
    			const degreesHoverMaxY = 5;
    			const hoverAngleDeltaZ = Math.sin((this._hoverAnimIdx) / 10) * Math.PI / 180 * degreesHoverMaxZ;
    			const hoverAngleDeltaY = Math.cos((this._hoverAnimIdx) / 10) * Math.PI / 180 * degreesHoverMaxY;

    			sleight.rotation.z = hoverAngleDeltaZ;
    			sleight.rotation.y = hoverAngleDeltaY;
    			this.positionRopeBothHands();
    		},
    	};
    }
    async function createReindeer(isRudolf: boolean, rowIdx: number) {
    	const reindeerUrl = 'https://firebasestorage.googleapis.com/v0/b/inovait-advent-of-code.appspot.com/o/AOC_STATIC%2Frudolf_v2.glb?alt=media&token=53d17ab0-9599-4ad4-bab3-4594b7ff14d2';
    	const noseMaterial = isRudolf ? new THREE.MeshBasicMaterial({ color: 0xff0000 }) : null;
    	const noseMesh = isRudolf ? new THREE.Mesh(new THREE.SphereGeometry(1), noseMaterial!) : null;
    	const nosePoint = isRudolf ? new THREE.Object3D() : null;
    	const noseBonePoint = isRudolf ? new THREE.Object3D() : null;
    	const noseBonePointOffsetAnimation = isRudolf ? new THREE.Object3D() : null;
    	let trailBuffer: any[] = [];
    	const pivot = new THREE.Object3D();
    	const { model: reindeer1, mixer: mixer1 } = await loadAndInitializeModel(reindeerUrl);
    	const { model: reindeer2, mixer: mixer2 } = await loadAndInitializeModel(reindeerUrl);
    	const ropePoints = {
    		reindeer1: { L: null as (THREE.Object3D | null), R: null as (THREE.Object3D | null) },
    		reindeer2: { L: null as (THREE.Object3D | null), R: null as (THREE.Object3D | null) },
    	};

    	const cylinder1 = createRopeCylinder();
    	const cylinder2 = createRopeCylinder();
    	const cylinderConnect = createRopeCylinder();

    	const getLRRopesBoneNodes = (reindeer: THREE.Group) => {
    		const LR = { L: null as (THREE.Object3D | null), R: null as (THREE.Object3D | null) };
    		const mapper: { [key: string]: Function } = { 'Rope-R': (v: any) => LR.R = v, 'Rope-L': (v: any) => LR.L = v };

    		reindeer.traverse((node) => {
    			if ((node as any).isBone) {
    				const fn = mapper[node.name];

    				if (fn) {
    					const geometry = new THREE.BoxGeometry(0.4, 0.4, 0.4);
    					const material = new THREE.MeshBasicMaterial({ color: 0xaaaa00 });
    					const cube = new THREE.Mesh(geometry, material);

    					// fixated for rope, to cover up "ending".
    					if (node.name.at(-1) === 'L') {
    						// bigger Z component, as neck is a bit "retarded".
    						cube.position.add(new THREE.Vector3(0, -0.1, -0.6));
    					} else {
    						cube.position.add(new THREE.Vector3(0, -0.1, -0.4));
    					}
    					node.add(cube);
    					fn(node);
    				}
    			}
    		});

    		return LR;
    	};

    	const reindeerOffsetLR = 0.7;

    	reindeer1.position.x = isRudolf ? 0.0 : +reindeerOffsetLR;
    	reindeer2.position.x = -reindeerOffsetLR;
    	mixer1.setTime(isRudolf ? 1.5 : 0);
    	mixer2.setTime(1);
    	pivot.add(reindeer1);

    	ropePoints.reindeer1 = getLRRopesBoneNodes(reindeer1);
    	ropePoints.reindeer2 = getLRRopesBoneNodes(reindeer2);

    	if (!isRudolf) {
    		pivot.add(reindeer2);
    	} else {
    		reindeer1.traverse(function (node) {
    			if ((node as any).isBone && node.name === 'head001') {
    				// rudolfNoseBoneHead = node;
    			}
    			if ((node as any).isBone && node.name === 'head002') {
    				// rudolfNoseBoneMouth = node;
    				// Create a green cube for each bone
    				// const geometry = new THREE.BoxGeometry(0.4, 0.4, 0.4);
    				// const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    				// const cube = new THREE.Mesh(geometry, material);

    				// Attach the cube to the bone
    				// node.add(cube);
    				node.add(noseBonePoint!);
                    noseBonePointOffsetAnimation!.position.set(0, 0.3, 0.1);
                    node.add(noseBonePointOffsetAnimation!);
    			}
    		});

            noseMesh!.scale.set(0.06, 0.06, 0.06);

            nosePoint!.scale.set(0.1, 0.1, 0.1);
            nosePoint!.position.set(0.05, 0.88, 1.95);

            reindeer1.add(nosePoint!);
    	}

    	return {
    		_hoverAnimIdx: Math.floor(Math.random() * 1000),
    		_wiggleIdx: 0,
    		speed: 0,
    		_angle: 0,
    		_rotation: 0,
    		_velocity: { x: 1, y: 0, z: 0 },
    		_forwardVector: null as THREE.Vector3 | null,
    		_nosePosition: null as THREE.Vector3 | null,
    		_noseIntensity: null as number | null,
    		isRudolf() {
    			return isRudolf;
    		},
    		getRopeCylinders() {
    			return { cylinder1, cylinder2, cylinderConnect };
    		},
    		getMesh() {
    			return pivot;
    		},
    		getReindeersLRNodes() {
    			return ropePoints;
    		},
    		getVelocity() {
    			return this._velocity;
    		},
    		setRotation(rotation: number) {
    			this._rotation = rotation;
    		},
    		setAngle(angle: number) {
    			this._angle = angle;
    			const dx = Math.cos(angle);
    			const dy = Math.sin(angle);

    			this._velocity.x = dx;
    			this._velocity.y = dy;
    		},
    		getAngle() {
    			return this._angle;
    		},
    		position: { x: 0, y: 0, z: 0 },
    		addToScene(scene: THREE.Scene | THREE.Group) {
    			if (isRudolf) {
    				scene.add(noseMesh!);
    			} else {
    				scene.add(cylinderConnect);
    			}
    			scene.add(cylinder1);
    			scene.add(cylinder2);
    			scene.add(pivot);
    		},
    		update() {
    			this._hoverAnimIdx += 0.1;
    			mixer1.update(animationSpeedUpdate);
    			mixer2.update(animationSpeedUpdate);

    			this.position.x += this._velocity.x * this.speed;
    			this.position.y += this._velocity.y * this.speed;
    			this.position.z += this._velocity.z * this.speed;

    			if (isRudolf) {
    				// wiggle the rudolf ;)
    				++this._wiggleIdx;
    				const wiggleScale = Math.sin(this._wiggleIdx / 200 * 2 * Math.PI) / 100;
    				const leftSidePerp = new THREE.Vector2(-this._velocity.y, this._velocity.x).normalize().multiplyScalar(wiggleScale);

    				this.position.x += leftSidePerp.x;
    				this.position.y += leftSidePerp.y;
    			}

    			// Uncomment for debugging while stationary @ different angles.
    			// this.position.x = 0;
    			// this.position.y = 0;
    			// this.position.z = 0;
    			// this._angle = 1 * Math.PI * 1.5 * 6 * 0.5;
    			// this._rotation = 1 * Math.PI * 0.5 * 2 * 0.5;

    			pivot.position.x = this.position.x;
    			pivot.position.y = this.position.y;
    			pivot.position.z = this.position.z;
    			trailBuffer.push({ rotation: this._rotation, angle: this._angle, position: { x: pivot.position.x, y: pivot.position.y, z: pivot.position.z } });

    			const zRotation = this._angle;
    			const xRotation = this._rotation;

    			const yQuaternion = new THREE.Quaternion();

    			yQuaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / 2);
    			const zQuaternion = new THREE.Quaternion();

    			zQuaternion.setFromAxisAngle(new THREE.Vector3(0, 0, 1), zRotation);
    			const xQuaternion = new THREE.Quaternion();

    			xQuaternion.setFromAxisAngle(new THREE.Vector3(1, 0, 0), xRotation);
    			pivot.quaternion.copy(zQuaternion).multiply(xQuaternion).multiply(yQuaternion);

    			const offsetA = Math.sin(this._hoverAnimIdx / 10) * 0.1;
    			const offsetB = Math.sin(this._hoverAnimIdx / 10 + Math.PI) * 0.1;

    			reindeer1.position.y = rowIdx % 2 === 0 ? offsetA : offsetB;
    			reindeer2.position.y = rowIdx % 2 === 0 ? offsetB : offsetA;

    			reindeer1.position.z = isRudolf ? 0 : rowIdx % 2 === 0 ? offsetA * 2 : offsetB * 2;
    			reindeer2.position.z = rowIdx % 2 === 0 ? offsetB * 2 : offsetA * 2;

    			const degreesHoverMax = (isRudolf ? 15 : 20);
    			const hover1Speed = isRudolf ? this._hoverAnimIdx * 2 : this._hoverAnimIdx;
    			const hoverAngleDelta1 = Math.sin((hover1Speed) / 10) * Math.PI / 180 * degreesHoverMax;
    			const hoverAngleDelta2 = Math.sin((this._hoverAnimIdx) / 10 + 1.5707963267948966 / 2) * Math.PI / 180 * degreesHoverMax;

    			reindeer1.rotation.z = hoverAngleDelta1;
    			reindeer2.rotation.z = hoverAngleDelta2;

    			if (isRudolf) {
    				/* rotate heads up! */
    				// let rudolf rotate the whole pack!
    				const upVec = new THREE.Vector3();

    				upVec.copy(pivot.up).applyQuaternion(pivot.quaternion);
    				// y < 0 means that sleight is turned upside down
    				// upVec.z must be near 0, meaning that the "up" vector lays on XY plane only
    				// if it lays like that it means that the sleight was successfully flipped.
    				if (upVec.y < 0 || Math.abs(upVec.z) > 0.01) {
    					this._rotation += 0.01;
    				} else {
    					// clip the rotation to a nice rotation so santa is perfectly aligned on XY plane
    					// otherwise the error of 0.01 would still apply causing the santa to be tilted outside of the plane a bit.
    					// round is needed because of compute errors... sometimes its really near a N*PI  and sometimes its really near N*(PI+1).
    					this._rotation = Math.PI * Math.round(this._rotation / Math.PI);
    				}

    				/* "Hack" to create an illusion of rudolf shiny nose.. To debug change the color of the "plane". */
    				const sinFraction = Math.abs(Math.sin(this._wiggleIdx / 100)) ** 2;

                    noseMaterial!.transparent = true;
                    noseMaterial!.opacity = (0.15 + sinFraction * 0.45);
                    const noseWorld = noseBonePoint!.getWorldPosition(new THREE.Vector3());
                    const noseWorldRenderPt = noseBonePointOffsetAnimation!.getWorldPosition(new THREE.Vector3());

                    noseWorldRenderPt.clone().sub(noseWorld);

                    const forwardVector = getWorldAxisDirection(nosePoint!, new THREE.Vector3(0, 0, 1)).normalize();

                    noseMesh!.position.copy(noseWorldRenderPt.clone().multiplyScalar(1 / SCALE));
                    this._noseIntensity = noseMaterial!.opacity;
                    this._forwardVector = forwardVector.clone().normalize();
                    this._nosePosition = noseWorldRenderPt.sub(forwardVector.clone().normalize().multiplyScalar(SCALE / 15));
    			}
    		},
    		getTrailBuffer() {
    			return trailBuffer;
    		},
    		clearTrailBuffer() {
    			trailBuffer = [];
    		},
    	};
    }

    function updateCylinderBetweenPoints(cylinder: THREE.Mesh<THREE.CylinderGeometry>, startPoint: THREE.Vector3, endPoint: THREE.Vector3) {
    	// Calculate the vector between start and end points
    	const direction = new THREE.Vector3().subVectors(endPoint, startPoint);
    	// Calculate the distance between the points (this will be the new height of the cylinder)
    	const length = direction.length();

    	// Update the scale of the cylinder to match the new length
    	cylinder.scale.set(1, length / cylinder.geometry.parameters.height, 1);

    	// Calculate the midpoint between the start and end points (this will be the new center of the cylinder)
    	const midpoint = new THREE.Vector3().addVectors(startPoint, endPoint).multiplyScalar(0.5);

    	// Update the position of the cylinder to the new midpoint
    	cylinder.position.copy(midpoint);

    	// Align the cylinder with the direction vector
    	const axis = new THREE.Vector3(0, 1, 0); // The default up vector of the cylinder geometry
    	const quaternion = new THREE.Quaternion().setFromUnitVectors(axis, direction.clone().normalize());

    	cylinder.setRotationFromQuaternion(quaternion);
    }

    const sleight = await createSantaSleight();
    const reinDeers = await Promise.all(new Array(5).fill(null).map((_, i) => createReindeer(i === 0, i)));
    const entities = [...reinDeers, sleight];
    const leadingReinDeer = reinDeers.at(0)!;

    leadingReinDeer.speed = reindeerSpeed;
    let isSleighInFOV = false;
    let mouseClick: { vec: THREE.Vector3; commitFrames: number } | null = null;

    function onClick(event: MouseEvent) {
    	const raycaster = new THREE.Raycaster();
    	const mouse = new THREE.Vector2();

    	mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    	mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    	raycaster.setFromCamera(mouse, camera);
    	const planeZ = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
    	const point = new THREE.Vector3();

    	raycaster.ray.intersectPlane(planeZ, point);
    	mouseClick = { vec: point.multiplyScalar(1 / SCALE), commitFrames: 60 * 20 /* ~20s if ran at 60fps */ };
    }

    function vector3({ x, y, z }: { x: number; y: number; z: number }) {
    	return new THREE.Vector3(x, y, z);
    }

    function connectDeers(frontDeer: ReinDeerType, backDeer: ReinDeerType) {
    	const { cylinder1, cylinder2, cylinderConnect } = frontDeer.getRopeCylinders();
    	const frontDeerOutAxis = getWorldAxisDirection(frontDeer.getMesh(), new THREE.Vector3(1, 0, 0)).multiplyScalar(1 / SCALE);
    	const frontNodes = frontDeer.getReindeersLRNodes();
    	const backNodes = backDeer.getReindeersLRNodes();

    	if (!frontDeer.isRudolf()) {
    		const startPoint = frontNodes.reindeer2.L!.getWorldPosition(new THREE.Vector3()).multiplyScalar(1 / SCALE);
    		const endPoint = backNodes.reindeer2.L!.getWorldPosition(new THREE.Vector3()).multiplyScalar(1 / SCALE);

    		updateCylinderBetweenPoints(cylinder1, startPoint, endPoint);
    		const startPoint2 = frontNodes.reindeer1.R!.getWorldPosition(new THREE.Vector3()).multiplyScalar(1 / SCALE);
    		const endPoint2 = backNodes.reindeer1.R!.getWorldPosition(new THREE.Vector3()).multiplyScalar(1 / SCALE);

    		updateCylinderBetweenPoints(cylinder2, startPoint2, endPoint2);
    		updateCylinderBetweenPoints(cylinderConnect, startPoint, startPoint2);
    	} else {
    		const startPoint = frontNodes.reindeer1.L!.getWorldPosition(new THREE.Vector3()).multiplyScalar(1 / SCALE).add(frontDeerOutAxis.multiplyScalar(0.1));
    		const endPoint = backNodes.reindeer1.R!.getWorldPosition(new THREE.Vector3()).multiplyScalar(1 / SCALE);

    		updateCylinderBetweenPoints(cylinder1, startPoint, endPoint);
    		const startPoint2 = frontNodes.reindeer1.R!.getWorldPosition(new THREE.Vector3()).multiplyScalar(1 / SCALE).add(frontDeerOutAxis.multiplyScalar(-0.1));
    		const endPoint2 = backNodes.reindeer2.L!.getWorldPosition(new THREE.Vector3()).multiplyScalar(1 / SCALE);

    		updateCylinderBetweenPoints(cylinder2, startPoint2, endPoint2);
    	}
    }

    function updateRopes() {
    	const lastReinDeer = reinDeers.at(-1)!;

    	for (let i = 1; i < reinDeers.length; i++) {
    		const frontDeer = reinDeers[i - 1]!;
    		const reinDeer = reinDeers[i]!;

    		connectDeers(frontDeer, reinDeer);
    	}
    	connectDeers(lastReinDeer, sleight.getReindeerRopePointsMock() as ReinDeerType);
    }

    const isGroupVisibleFrustum = new THREE.Frustum();
    const isGroupVisibleMatrix = new THREE.Matrix4();
    const isGroupVisibleBox3 = new THREE.Box3();

    function isGroupVisible(group: THREE.Group, camera: THREE.OrthographicCamera) {
    	group.updateMatrixWorld();
    	const box = isGroupVisibleBox3.setFromObject(group);

    	box.min.x -= SCALE * 10;
    	box.min.y -= SCALE * 10;
    	box.max.x += SCALE * 10;
    	box.max.y += SCALE * 10;
    	isGroupVisibleMatrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
    	isGroupVisibleFrustum.setFromProjectionMatrix(isGroupVisibleMatrix);

    	return isGroupVisibleFrustum.intersectsBox(box);
    }

    function onUpdate() {
    	const trailSize = 30;
    	const santaTrailSize = 30;

    	if (mouseClick && --mouseClick.commitFrames < 0) {
    		mouseClick = null;
    	}

    	if (mouseClick) {
    		const clickV3 = vector3(mouseClick.vec);
    		const deerV3 = vector3(leadingReinDeer.position);

    		clickV3.sub(deerV3).normalize();
    		const deerVelocity = leadingReinDeer.getVelocity();

    		if (!isSleighInFOV && !leadingReinDeer.speed) {
    			rngScale();
    			reinDeers.forEach((deer) => { deer.clearTrailBuffer(); });
    			const angle = Math.atan2(clickV3.y, clickV3.x);

    			leadingReinDeer.setAngle(angle);
    			// prepare "nice" rotation of deers, as otherwise animation rotation will kick in (if deers are heads down).
    			leadingReinDeer._rotation = Math.abs(angle) > Math.PI / 2 ? Math.PI : 0;
    			leadingReinDeer.speed = reindeerSpeed;
    			mouseClick = null;
    		} else {
    			const direction = (new THREE.Vector2(deerVelocity.x, deerVelocity.y)).cross(new THREE.Vector2(clickV3.x, clickV3.y));
    			const deerAngle = leadingReinDeer.getAngle();

    			const steerStrength = 0.5;

    			leadingReinDeer.setAngle(deerAngle + (direction > 0 ? Math.PI / 180 : -Math.PI / 180) * steerStrength);

    			if (Math.abs(direction) < 0.01) mouseClick = null;
    		}
    	}

    	for (let i = 1; i < reinDeers.length; i++) {
    		const frontDeer = reinDeers[i - 1];
    		const reinDeer = reinDeers[i];
    		const trailBuffer = frontDeer.getTrailBuffer();

    		if (trailBuffer.length > trailSize) {
    			const trail = trailBuffer.shift();

    			reinDeer.position.x = trail.position.x;
    			reinDeer.position.y = trail.position.y;
    			reinDeer.position.z = trail.position.z;
    			reinDeer.setRotation(trail.rotation);
    			reinDeer.setAngle(trail.angle);
    		}
    	}

    	const lastReinDeer = reinDeers.at(-1)!;
    	const trailBuffer = lastReinDeer.getTrailBuffer();

    	if (trailBuffer.length > santaTrailSize) {
    		const trail = trailBuffer.shift();

    		sleight.position.x = trail.position.x;
    		sleight.position.y = trail.position.y;
    		sleight.position.z = trail.position.z;
    		sleight.setRotation(trail.rotation);
    		sleight.setAngle(trail.angle);
    	}

    	sleight.update();
    	reinDeers.forEach((r) => r.update());
    	updateRopes();

    	if (isGroupVisible(GROUP, camera)) {
    		if (!isSleighInFOV) {
    			leadingReinDeer.speed = reindeerSpeed;
    		}
    		isSleighInFOV = true;
    	} else {
    		if (isSleighInFOV) {
    			leadingReinDeer.speed = 0;
    			const shouldAutopilotSanta = !mouseClick && !checkIfInEventsRegex.test(window.location.pathname);

    			if (shouldAutopilotSanta) {
    				const w = window.innerWidth;
    				const h = window.innerHeight;
    				const randSign = () => (Math.random() * 2 - 1);
    				const rngw = w * 0.5 + (w * 0.4 * randSign());
    				const rngh = h * 0.5 + (h * 0.4 * randSign());

    				onClick({ clientX: rngw, clientY: rngh } as MouseEvent);
    			}
    		}
    		isSleighInFOV = false;
    	}
    }

    const light = new THREE.AmbientLight(0xaaaaaa, 1);

    GROUP.add(light);
    sleight.addToScene(GROUP);
    reinDeers.forEach((deer) => {
    	deer.addToScene(GROUP);
    });
    mainScene.add(GROUP);
    const v = (new THREE.Vector3(1, 1, 1)).multiplyScalar(SCALE);

    GROUP.scale.set(v.x, v.y, v.z);

    function rngScale() {
    	const oldScale = SCALE;

    	SCALE = BASE_SCALE + Math.floor(Math.sin(Math.PI * 2 * Math.random()) * BASE_SCALE * 0.5);
    	const v = (new THREE.Vector3(1, 1, 1)).multiplyScalar(SCALE);

    	// cluster them on the same point
    	// this prevents flickering where resize is being done on the edge of the screen (santa "flashing" as result of resize)
    	entities.forEach((entity) => {
    		entity.position.x = leadingReinDeer.position.x;
    		entity.position.y = leadingReinDeer.position.y;
    		entity.position.z = leadingReinDeer.position.z;
    	});

    	GROUP.scale.set(v.x, v.y, v.z);

    	reinDeers.forEach((deer) => { deer.clearTrailBuffer(); });
    	entities.forEach((entity) => {
    		entity.position.x *= (oldScale / SCALE);
    		entity.position.y *= (oldScale / SCALE);
    		entity.position.z = -5;
    	});
    }

    const state = {
    	onUpdate,
    	setVisibility(visible: boolean) {
    		GROUP.visible = visible;
    	},
    	getDirectionVector() {
    		return leadingReinDeer._forwardVector;
    	},
    	getRudolphNosePosition() {
    		return leadingReinDeer._nosePosition;
    	},
    	getRudolphNoseIntensity() {
    		return leadingReinDeer._noseIntensity;
    	},
    	getRudolphScale() {
    		const maxScale = BASE_SCALE + BASE_SCALE * 0.5;
    		const minScale = BASE_SCALE - BASE_SCALE * 0.5;
    		const maxMinRange = maxScale - minScale;
    		const range = SCALE - minScale;

    		return range / maxMinRange;
    	},
    	reset() {
    		entities.forEach((entity) => {
    			entity.position.x = -5 * SCALE * (1 / SCALE);
    			entity.position.y = -window.innerHeight * (1 / SCALE);
    			entity.position.z = -5;
    		});
    		reinDeers.forEach((deer) => {
    			deer.clearTrailBuffer();
    		});
    		leadingReinDeer.setAngle(Math.PI / 16);
    	},
    	onClick,
    };

    state.reset();

    return state;
}
