export const getCandyCaneVertexShader = () => `
varying vec2 vUv;
void main() {
    vUv = uv;
    gl_Position = vec4(position, 1.0);
}
`;

export const getCandyCaneFragmentShader = () => `
uniform float iGlobalTime;
uniform vec3 caneRed;
uniform vec3 caneWhite;
uniform vec3 background;
uniform float height;

uniform vec2 spotOrigin;
uniform vec2 spotDirection; 
uniform float spotIntensity;
uniform float reindeerScale;

varying vec2 vUv;

float smoothFalloff(float edge0, float edge1, float x) {
    float t = smoothstep(edge0, edge1, x);
    return clamp(t, 0.0, 1.0);
}

int modOp(int a, int b) {
  return a - (b * int(a / b));
}

void main() {
    float stripeW = 30.0;
    float stripeH = 6.0;
    float topBarH = 25.0;

    // Roll time offset into the future, because of render "lag" near division remainder by zero (didn't bother to fix).
    float vt = iGlobalTime * 0.5 + 100000.0;
    int stripeRowIdx = int((-vt + gl_FragCoord.y) / stripeH);
    int stripeColIdx = int((gl_FragCoord.x - float(stripeRowIdx) * stripeW * 0.20) / stripeW);
    bool renderStripe = modOp(stripeColIdx, 2) == 0;
    bool inRenderBox = gl_FragCoord.y >= (height - topBarH);

    vec4 baseColor = !inRenderBox ? vec4(background, 1.0) :
                     renderStripe ? vec4(caneRed, 1.0) : vec4(caneWhite, 1.0);

    float t = iGlobalTime * 0.02;
    float spotAngle = radians(20.0); // Spotlight cone angle in radians (20~30 degrees looks nice)
    float spotLength = 300.0 - 220.0 * (1.0-reindeerScale); // Maximum reach of the spotlight
    float softness = 0.5 + (spotIntensity - 0.5)*2.0 * 0.3; // How soft the edges of the spotlight are

    // Calculate the vector from the spotlight's origin to the fragment
    vec2 fragCoord = gl_FragCoord.xy;
    vec2 lightVector = fragCoord - spotOrigin;
    float distanceFromSpot = length(lightVector); // Distance from the spotlight origin to the fragment

    // Only proceed if the fragment is within the light's reach
    if (distanceFromSpot <= spotLength) {
        vec2 normalizedLightVector = normalize(lightVector); // Direction vector from the spotlight to the fragment
        float angleToFragment = acos(dot(normalizedLightVector, spotDirection)); // Angle between spotlight direction and fragment

        // Soft spotlight edge
        float angleFactor = smoothFalloff(spotAngle, spotAngle + softness, angleToFragment);

        // Create a falloff for light intensity based on distance (more natural attenuation)
        float distanceFactor = smoothstep(0.0, spotLength, distanceFromSpot);

        // Combine distance and angle factors to form the final intensity
        float intensity = (1.0 - distanceFactor) * (1.0 - angleFactor);

        intensity *= spotIntensity;
        vec3 spotlightColor = mix(baseColor.rgb, caneRed, intensity);

        // Final color of the spotlight with soft falloff
        gl_FragColor = vec4(spotlightColor, 1.0);
        return;
    }

    // Default color outside the spotlight
    gl_FragColor = baseColor;
}`;
