export default class AOCError extends Error {
	errorCode: string;
	additionalData: { [name: string]: any };

	constructor(message = 'Oops, nekaj je šlo narobe(nedokumentirana napaka).', errorCode: string, additionalData: { [name: string]: any }) {
		super(message);
		this.errorCode = errorCode;
		this.additionalData = additionalData;
	}
}
