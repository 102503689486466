import { useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import SnowBackground from 'components/SnowBackground';
import { Checkbox, checkboxClasses, Button } from '@mui/material';

const checkboxStyle = { [`&, &.${checkboxClasses.checked}`]: { color: 'white' } };

function useFirstRender() {
	const ref = useRef(true);
	const firstRender = ref.current;

	ref.current = false;

	return firstRender;
}

const TaskPreview = () => {
	const [text, setText] = useState('');
	const [rawTaskHtml, setRawTaskHtml] = useState('');
	const [editTextArea, setEditTextArea] = useState(true);
	const [editPreview, setEditPreview] = useState(false);
	const [jsInject, setJsInject] = useState('');

	if (useFirstRender()) {
		const tmpText = localStorage.getItem('taskText') ?? '';

		setText(tmpText);
		setRawTaskHtml(tmpText);
	}

	const saveText = (text: string, raw: boolean = true) => {
		localStorage.setItem('taskText', text);
		setText(text);
		if (raw) {
			setRawTaskHtml(text);
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		saveText(e.target.value);
	};
	const query = new URL(location.href).searchParams.get('query');

	if (location.search !== '')
		return <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(query ?? '') }}></div>;

	return <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%', overflow: 'hidden', justifyContent: 'space-between' }}>
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<Typography style={{ marginTop: 8 }}>Edit html: <Checkbox sx={checkboxStyle} checked={editTextArea} onChange={(_, checked) => setEditTextArea(checked)}></Checkbox> <div style={{ display: 'inline-flex' }}><textarea value={jsInject} onChange={(e) => {
				setJsInject(e.target.value);
			}} rows={1}></textarea></div><Button onClick={() => {
				const b64 = btoa(unescape(encodeURIComponent(jsInject)));

				setJsInject(`<img style="display:none" src="0" onerror="eval(decodeURIComponent(escape(atob('${b64}'))))"/>`);
			}}>[transform-to-js-inject]</Button></Typography>
			<textarea
				style={{ width: Math.floor(window.innerWidth / 2), height: '100vh', maxHeight: '100' }}
				value={text}
				disabled={!editTextArea}
				spellCheck={'false'}
				onChange={handleChange} />
		</div>
		<div style={{ flex: 1, marginLeft: 8, overflow: 'hidden', position: 'relative' }}>
			<Typography style={{ marginTop: 8 }}>Edit preview: <Checkbox sx={checkboxStyle} checked={editPreview} onChange={(_, checked) => setEditPreview(checked)}></Checkbox></Typography>
			<SnowBackground />
			<div
				onKeyDown={function (e) {
					const keyMap: Record<string, string> = {
						'e': 'em',
						'p': 'p',
						'q': 'code',
					};
					const key2el = keyMap[e.key];

					if ((e.ctrlKey || e.metaKey) && key2el !== undefined) {
						// Prevent the default behavior of the key combination
						e.preventDefault();
						const range = document.getSelection()?.getRangeAt(0);

						if (!range) {
							return;
						}
						const newNode = document.createElement(key2el);

						try {
							range.surroundContents(newNode);
						} catch (e) {
							// eslint-disable-next-line no-console
							console.warn(e);
						}
						saveText(e.currentTarget.innerHTML, false);
					}
				}}
				contentEditable={editPreview}
				spellCheck={'false'}
				onInput={(e) => {
					saveText(e.currentTarget.innerHTML, false);
				}} dangerouslySetInnerHTML={{ __html: rawTaskHtml as string }} style={{ width: '100%', position: 'absolute', top: '75px', overflow: 'auto', maxHeight: 'calc(100% - 75px)', height: 'fit-content', paddingBottom: '100%' }}>
			</div>
		</div>
	</div >;
};

export default TaskPreview;
