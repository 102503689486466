import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../App';

interface IProtectedRoute {
	children?: React.ReactNode;
};

const ProtectedRoute: React.FC<IProtectedRoute> = (props) => {
	const user = useContext(UserContext);

	if (!user) {
		return <Navigate replace to='/' />;
	}

	return <>{props.children}</>;
};

export default ProtectedRoute;
