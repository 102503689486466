import { configureStore } from '@reduxjs/toolkit';
import tasksReducer from 'state/tasks/tasksSlice';
import eventsReducer from 'state/events/eventsSlice';

export const store = configureStore({
	reducer: {
		tasks: tasksReducer,
		events: eventsReducer,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
