import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useIsInternalUser } from 'hooks/useIsInternalUser';

const StyledTypography = styled(Typography)(() => ({
	fontSize: '18px!important',
	color: 'red',
}));

const Rules = () => {

	const isInternal = useIsInternalUser();

	const classicTaskHtml = isInternal
		? <li>* <em>Klasične naloge</em> (<AcUnitIcon color={'success'}></AcUnitIcon>) sestavlja <span style={{ color: 'gold' }}>eden</span> ali <span style={{ color: 'gold' }}>več</span> delov, kjer je vsak odklenjen del naloge vreden <span style={{ color: 'gold' }}>100</span> točk. Deli se ob reševanju naloge <span style={{ color: 'gold' }}>odkrivajo sproti</span>, tako ne veš koliko delov ima dana naloga, dokler ne prideš do <span style={{ color: 'gold' }}>zaključka</span>. Prve <span style={{ color: 'gold' }}>3</span> dni od odprtja naloge se za vsak pravilno rešen del dobi <span style={{ color: 'gold' }}>100</span> točk. Nato se število točk za pravilno rešitev <span style={{ color: 'red' }}>zmanjša</span> za <span style={{ color: 'gold' }}>10</span> točk vsakih <span style={{ color: 'gold' }}>24</span> ur, do minimuma <span style={{ color: 'gold' }}>10</span> točk</li>
		: <li>* <em>Klasične naloge</em> (<AcUnitIcon color={'success'}></AcUnitIcon>) sestavlja <span style={{ color: 'gold' }}>eden</span> ali <span style={{ color: 'gold' }}>več</span> delov, kjer je vsak odklenjen del naloge vreden <em>največ</em> <span style={{ color: 'gold' }}>1000</span> točk. Deli se ob reševanju naloge <span style={{ color: 'gold' }}>odkrivajo sproti</span>, tako ne veš koliko delov ima dana naloga, dokler ne prideš do <span style={{ color: 'gold' }}>zaključka</span>.<span style={{ color: 'red' }}>  Od odprtja naloge, <em>se vsako uro</em> zmanjša maksimalno število točk naloge za <em>1</em> točko. Zraven <em>časovnega odbitka</em> je prisoten tudi <em>odbitek glede na vrstni red</em>, ki se nanaša na to, <em>kateri po vrsti rešiš nalogo</em>. <span style={{ color: 'gold' }}>V primeru, da nalogo rešiš prvi, je tvoj odbitek <em>0</em> točk!</span> Sicer se za vsako oddano uspešno rešitev pred teboj, odbitek poveča za <em>-1</em>.</span> Tako je vsak pravilno rešen del točkovan po enačbi: <b>&ldquo;</b><span style={{ color: 'gold' }}>1000</span> &minus; <i style={{ color: 'red' }}>čas</i> &minus; <i style={{ color: 'red' }}>vrstni_red</i><b>&rdquo;</b>. Minimalno lahko dosežeš <span style={{ color: 'gold' }}>100</span> točk.</li>;

	const evalutedPointsReductionHtml = isInternal
		? <>Tukaj se točke, ob oddaji naloge, čez določeno število dni <span style={{ color: 'gold' }}>ne zmanjšujejo</span>, kot v klasičnih nalogah</>
		: <>Tukaj se točkam <span style={{ color: 'gold' }}>ne odšteva</span> <em>časovni odbitek</em> in <em>odbitek glede na vrstni red</em> (kot v klasičnih nalogah)</>;

	const evaluatedMaxPoints = isInternal ? 200 : 2000;

	return (
		<div style={{ padding: '16px', paddingBottom: '0px' }}>
			<StyledTypography variant='h4'>Pravila:</StyledTypography>
			<ul style={{ fontSize: '1.0rem', fontFamily: '\'Press Start 2P\'', listStyleType: 'none' }}>
				<li style={{ fontSize: 22 }}>{'>'} Tipi nalog in točkovanje</li>
				<hr></hr>
				<br />
				{classicTaskHtml}
				<br />
				<li>* Pri <em>optimizacijskih nalogah</em> (<AutoAwesomeIcon color={'success'}></AutoAwesomeIcon>), kjer je možnih <span style={{ color: 'gold' }}>več rešitev</span>, se točkovanje razlikuje od klasičnega. Tam <em>najboljša rešitev</em> dobi <span style={{ color: 'gold' }}>{evaluatedMaxPoints}</span> točk, vsaka <span style={{ color: 'red' }}>slabša rešitev</span> pa dobi nekoliko <span style={{ color: 'gold' }}>manj</span> točk. {evalutedPointsReductionHtml}. Se pa z <span style={{ color: 'red' }}>vsako ponovno oddajo</span> naloge, <span style={{ color: 'red' }}>poveča čakalni čas</span> za naslednjo oddajo (do največ ene ure). Rešitev je tako možno <span style={{ color: 'gold' }}>oddati večkrat</span>, da izboljšaš svoj rezultat in premešaš lestvico. V primeru, da <span style={{ color: 'red' }}>oddaš slabšo ali enako rešitev, kot je tvoja najboljša, jo bo sistem zavrnil!</span> V kolikor različna tekmovalca oddata dve <span style={{ color: 'red' }}>identični rešitvi</span>, se upošteva <span style={{ color: 'gold' }}>hitrejši čas oddaje</span></li>
				<br />
				<li>* Za vsak <span style={{ color: 'red' }}>neuspel poskus</span> zapraviš čas Božičku 🎅, <span style={{ color: 'gold' }}>tretji</span> poskus in <span style={{ color: 'gold' }}>nadaljni</span> prinašajo <span style={{ color: 'red' }}>začasno preprečitev oddaje dane naloge</span> (do največ ene ure), zato dobro premisli preden oddaš rešitev za katero nisi prepričan!</li>
				<br />
				<li>* Nedokončane naloge so obarvane z belo barvo (<AcUnitIcon /> / <AutoAwesomeIcon />), ob vseh rešenih delih pa se obarvajo zlato (<AcUnitIcon color={'success'} /> / <AutoAwesomeIcon color={'success'} />), kar označuje <span style={{ color: 'gold' }}>uspešno zaključeno nalogo</span></li>
				<br />
				<br />
				<li style={{ fontSize: 22 }}>{'>'} Lestvice</li>
				<hr></hr>
				<br />
				<li>* <em>Glavna lestvica 🏆</em> - Tukaj se nahajajo najboljši Božičkovi pomagači! Za dostop do lestvice klikneš na gumb [LESTVICA] v navigacijskem meniju. <span style={{ color: 'gold' }}>Na lestvico se uvrsti le prvih 100 najboljših tekmovalcev</span> (tisti z enakim številom točk si delijo isto mesto, vendar bodo na lestvici urejeni po skupnem času reševanja).</li>
				<br></br>
				<li><span style={{ color: 'red' }}>! Pozor:</span> <em>Lestvice se posodabljajo le ob oddaji rešitev, s približnim zamikom 5 minut</em>, zato boš svoje rezultate na lestvici videl zakasnjeno.</li>
				<br />
				<li>* Tekmovalci se lahko tudi izkažejo na <span style={{ color: 'gold' }}>pod-lestvicah</span> posamičnih nalog. Naslednje najdeš na strani, kjer se nahaja glavna lestvica: </li>
				<br></br>
				<li>* <em>Brzinkova lestvica 🚀</em> - Tukaj se nahajajo absolutno najhitrejši Božičkovi pomagači, ki najhitreje rešijo vse dele naloge, takoj ko je ta na voljo! <span style={{ color: 'gold' }}>Lestvica sešteva čas, ki ga tekmovalec potrebuje od odprtja do uspešnega zaključka posamezne naloge</span></li>
				<br />
				<li>* <em>Lestvica vročega stola 🔥</em> - Tukaj se nahajajo relativno najhitrejši Božičkovi pomagači, ki čim hitreje rešijo vse dele naloge, ne glede na čas, ko se je naloga odprla! <span style={{ color: 'gold' }}>Lestvica sešteva čas, ki ga tekmovalec potrebuje od uspešne oddaje prvega dela do uspešnega zaključka posamezne naloge</span></li>
				<br />
				<li>* <em>Lestvica perfektnih prstkov 👌🏻</em> - Tukaj se nahajajo najbolj pedantni Božičkovi pomagači, ki oddajo nalogo le, ko so v svojo rešitev popolnoma prepričani! <span style={{ color: 'gold' }}>Lestvica sešteva število oddaj, ki jih tekmovalec naredi do uspešnega zaključka posamezne naloge</span></li>
				<br />
				<li>* <em>Lestvica optimizatorjev ⚙️</em> - Ta lestvica predstavlja zbirališče najboljših strategov med Božičkovimi pomagači, ki oddajo le najbolj optimalne rešitve. <span style={{ color: 'gold' }}>Lestvica sešteva število doseženih točk pri optimizacijskih nalogah</span> (<AutoAwesomeIcon color={'success'}></AutoAwesomeIcon>)</li>
				<br />
				<li><span style={{ color: 'red' }}>! Pozor:</span> Statistike optimizacijskih nalog (<AutoAwesomeIcon color={'success'}></AutoAwesomeIcon>) <span style={{ color: 'red' }}>so izključno za <em>lestvico optimizatorjev ⚙️</em></span>. Tako reševanje teh tipov nalog <span style={{ color: 'red' }}>ni všteto v ostale globalne pod-lestvice (🚀, 🔥, 👌🏻)</span></li>
				<br />
				<li>! Na slednjih pod-lestvicah se <span style={{ color: 'gold' }}>nahajajo samo tekmovalci, ki so rešili vse trenutno odklenjene naloge.</span><i> Ker se lestvica posodablja le ob oddaji rešitev, je ob odprtju nove naloge še nekaj časa prisotna starejša lestvica (preden tekmovalci začnejo z oddajanjem novih rešitev).</i> <span style={{ color: 'red' }}>Izjema je le lestvica optimizatorjev</span>, ki ne zahteva vseh rešenih nalog, saj se tam točke maksimizirajo in ne-sodelovanje pri novih nalogah ne ponuja prednosti.</li>
				<br />
				<li>! <span style={{ color: 'gold' }}>Vsaka naloga ima tudi svojo lokalno glavno lestvico in pod-lestvice</span>, ki se osredotočijo le na dano nalogo. Do teh lestvic lahko prideš na seznamu posameznih nalog, kjer je zraven naloge, na desni, gumb [LESTVICA]</li>
				<br />
			</ul>
		</div>
	);
};

export default Rules;
