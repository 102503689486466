import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/system';
import CssBaseline from '@mui/material/CssBaseline/CssBaseline';
import theme from './styles/AOCTheme';
import './styles/index.css';
import { store } from 'state/store';
import { Provider } from 'react-redux';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<App/>
				</ThemeProvider>
			</Provider>
		</BrowserRouter>
	</React.StrictMode>,
);
