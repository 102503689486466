export const vertexShaderPainting = `
varying vec2 vUv;
void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;
export const fragmentShaderPainting = `
uniform sampler2D u_matrix;
uniform vec2 u_resolution;
varying vec2 vUv;
void main() {
    vec2 uv = vUv * u_resolution;
    vec4 color = texture2D(u_matrix, uv / u_resolution);
    float value = color.r;
    gl_FragColor = value == 1.0 ? vec4(0.0, 0.0, 0.0, 1.0) : (value == 0.0 ? vec4(1.0, 1.0, 1.0, 1.0) : vec4(0.0, 0.0, 0.0, 0.0));
}
`;

