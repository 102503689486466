import React, { useState } from 'react';
import { Part, Task } from '../../types/Task';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CountdownTimer from '../CountdownTimer';
import VerifiedIcon from '@mui/icons-material/Verified';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { FormHelperText, TextField } from '@mui/material';
import { SpecialBottomComponent } from './Specials/SpecialBottomComponent';
import { SpecialInputComponent } from './Specials/SpecialInputComponent';
import { useAdventOfDesignDependencies } from './Specials/AdventOfDesign/use-dependency';

const StyledForm = styled('form')(() => ({
	display: 'flex',
	alignItems: 'center',
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

interface TaskPartDetailsProps {
	// backend never exposes if task part is last. Only known if task is completed and it's last part in array.
	isLastPart: boolean;
	taskPart: Part;
	eventId: string;
	taskId: string;
	onDownloadPartHistory: (part: number) => Promise<void>;
	onSubmitAnswer: (part: number, answer: string | Uint8Array) => Promise<void>;
	onGetPuzzleInput: (part: number, options: { download: boolean }) => Promise<string>;
	additionalData?: { retrySeconds?: number };
	taskType: Task['type'];
	evaluatorFunction?: string;
}

const TaskPartDetails: React.FC<TaskPartDetailsProps> = (props) => {
	const { eventId, taskId, taskPart: { partNumber, partHtml, partUnlockHtml, isSolved, solution }, onGetPuzzleInput, onSubmitAnswer, onDownloadPartHistory, additionalData, taskType, isLastPart, evaluatorFunction } = props;
	const [answer, setAnswer] = useState('');
	const [hasHiddenSymbols, setHasHiddenSymbols] = useState(false);
	const [isInputFetching, setIsInputFetching] = useState(false);
	const [isCheckingSolution, setIsCheckingSolution] = useState(false);
	const [isFetchingHistory, setIsFetchingHistory] = useState(false);
	const theme = useTheme();

	const transitionDuration = {
		enter: theme.transitions.duration.enteringScreen,
		exit: theme.transitions.duration.leavingScreen,
	};

	const handleSubmitAnswer = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			setIsCheckingSolution(true);
			await onSubmitAnswer(partNumber, answer);
		} finally {
			setIsCheckingSolution(false);
		}
	};

	const handleGetPartInput = async (download: boolean) => {
		try {
			setIsInputFetching(true);
			await onGetPuzzleInput(partNumber, { download });
		} finally {
			setIsInputFetching(false);
		}
	};

	const handleDownloadPartHistory = async () => {
		try {
			setIsFetchingHistory(true);
			await onDownloadPartHistory(partNumber);
		} finally {
			setIsFetchingHistory(false);
		}
	};

	const hasInputAvailable = (!isSolved || taskType === 'evaluated');
	const hasHistoryButton = evaluatorFunction !== 'AdventOfDesign';
	const isSpecialDesign = evaluatorFunction === 'AdventOfDesign';
	const specialTaskName = isSpecialDesign && taskType === 'evaluated'
		? evaluatorFunction
		: isSpecialDesign && taskType !== 'evaluated'
			? `${eventId}-${taskId}-${partNumber}`
			: null;

	const adventOfDesignDeps = useAdventOfDesignDependencies(evaluatorFunction, eventId, taskId, partNumber);

	return <>
		<Accordion defaultExpanded={hasInputAvailable} disableGutters elevation={0} square style={{ marginBottom: 16 }}>
			<AccordionSummary>
				<Typography>{partNumber + 1}. del {isSolved && <>
					<Zoom
						in={true}
						timeout={transitionDuration}
						style={{
							transitionDelay: `${transitionDuration.exit * partNumber}ms`,
						}}
						unmountOnExit
					>
						<VerifiedIcon fontSize='small' color='success' />
					</Zoom>
				</>}</Typography>
			</AccordionSummary>
			<AccordionDetails style={hasInputAvailable ? { padding: '8px 16px 8px' } : {}}>
				<div dangerouslySetInnerHTML={{ __html: partHtml as string }}></div>
				<hr />
				<div style={{ padding: '15px 0px 15px 0px' }}><Typography>Prenesi vhodne podatke izziva<Button disabled={isInputFetching} onClick={() => handleGetPartInput(true)}>[tukaj]</Button></Typography></div>
				{isSolved && <div style={{ marginTop: 16 }}>
					{(solution != undefined) && <Typography>{taskType === 'evaluated' ? 'Tvoja zadnja sprejeta rešitev je bila: ' : 'Tvoja sprejeta rešitev je bila: '}<code>{solution}</code></Typography>}
				</div>}
				<CountdownTimer seconds={additionalData?.retrySeconds ?? 0}>
					{!isSpecialDesign && hasInputAvailable && <div>
						<StyledForm onSubmit={handleSubmitAnswer}>
							<Typography display='inline' style={{ marginBottom: 'auto', paddingTop: '4px', marginRight: 16 }}>Rešitev: </Typography>
							<div>
								<TextField variant='standard' value={answer} onChange={(e) => {
									setAnswer(e.target.value);
									setHasHiddenSymbols(/^[\s\t\n\r\v\f].*|.*[\s\t\n\r\v\f]$/.test(e.target.value));
								}}></TextField>
								<FormHelperText error={hasHiddenSymbols} sx={{ display: 'flex', justifyContent: 'center' }}>
									<span>{hasHiddenSymbols ? 'niz obdaja vsaj 1 skrit znak' : '\u00A0'}</span>
								</FormHelperText>
							</div>
							{isCheckingSolution && <Typography style={{ marginBottom: 'auto', paddingTop: '4px', paddingLeft: 16 }} >🎅 preverja tvojo rešitev, prosim počakaj...</Typography>}
							<Button disabled={isCheckingSolution} style={{ marginBottom: 'auto', paddingTop: '4px' }} type='submit'>Pošlji</Button>
						</StyledForm>
					</div>}
					{isSpecialDesign && hasInputAvailable && <SpecialInputComponent taskName={specialTaskName} partNumber={partNumber} onSubmitAnswer={onSubmitAnswer} />}
				</CountdownTimer>
				{isSpecialDesign && <SpecialBottomComponent taskName={specialTaskName} adventOfDesign={{ canvasRef: adventOfDesignDeps.canvasRef, hasGalleryAvailable: adventOfDesignDeps.galleryAvailable }} />}
				{hasHistoryButton && <Button disabled={isCheckingSolution || isFetchingHistory} onClick={() => handleDownloadPartHistory()} style={{ marginTop: '16px' }}>Prenesi zgodovino oddaj</Button>}
			</AccordionDetails>
		</Accordion >
		{partUnlockHtml && <Accordion defaultExpanded={isLastPart} disableGutters elevation={0} square style={{ marginBottom: 16 }}>
			<AccordionSummary>
				<Typography>{isLastPart ? 'Zaključek' : 'Vmesna prigoda'}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{<Typography><div dangerouslySetInnerHTML={{ __html: partUnlockHtml as string }}></div></Typography>}
			</AccordionDetails>
		</Accordion>
		}
	</>;
};

export default TaskPartDetails;
