import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { CANDYCANE_RED, PRIMARY } from 'styles/AOCTheme';
import { encodeSolution } from './AdventOfDesign/solution-encoder';

function GenericFileDragDropComponent(props: { onSubmit: (solution: string, onError: (message: string) => void) => void; sectionStyle?: React.CSSProperties }) {
	const [disableInput, setDisableInput] = useState(false);

	const { getRootProps,
		getInputProps,
		acceptedFiles,
		isFocused,
		isDragAccept,
		isDragReject } = useDropzone({
		accept: { 'text/plain': ['.txt'] }, maxFiles: 1, disabled: disableInput,
	});

	const enabledColor = PRIMARY;
	const disabledColor = '#4af6264f';
	const formColor = disableInput ? disabledColor : enabledColor;
	const baseStyle = {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 15,
		borderColor: formColor,
		borderStyle: 'dashed',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	};

	const focusedStyle = {
		borderColor: formColor,
	};

	const acceptStyle = {
		borderColor: formColor,
	};

	const rejectStyle = {
		borderColor: CANDYCANE_RED,
	};

	const style = ({
		...baseStyle,
		...(isFocused ? focusedStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {}),
	}) as React.CSSProperties;
	const file = acceptedFiles[0] ?? null;
	const [errorMsg, setErrorMsg] = useState('');

	if (errorMsg && !file) setErrorMsg('');

	return (
		<section className="container" style={props?.sectionStyle ?? {}}>
			<div {...getRootProps({ style })}>
				<input {...getInputProps()} />
				{disableInput ? <Typography style={{ marginBottom: 'auto', paddingTop: '4px', paddingLeft: 16 }} >🎅 preverja tvojo rešitev, prosim počakaj...</Typography> : <a style={{ cursor: 'pointer', fontSize: 30 }}>Klikni ali potegni svojo rešitev sem</a>}
				{file && <i>{file.name}</i>}
				{file && <Button style={{ marginTop: 10 }} onClick={async (e) => {
					try {
						e.stopPropagation();
						setDisableInput(true);
						if (e.currentTarget) {
							e.currentTarget.style.display = 'none';
						}
						const textContent = await file.text();

						await props.onSubmit(textContent, (msg: string) => { setErrorMsg(msg); });
					} finally {
						setDisableInput(false);
						if (e.currentTarget) {
							e.currentTarget.style.display = '';
						}
					}
				}} size='large'>Pošlji</Button>}
				{errorMsg && file ? <p style={{ textAlign: 'center', width: '100%', color: 'red', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{errorMsg}</p> : null}
			</div>
		</section>
	);
}

export function SpecialInputComponent(props: { partNumber: number; taskName: string | null; onSubmitAnswer: (part: number, answer: string | Uint8Array) => Promise<void> }) {
	switch (props.taskName) {
		case 'AdventOfDesign': {
			const handleSubmitAnswerAdventOfDesign = async (textInput: string, onError: (msg: string) => void) => {
				const buffer = encodeSolution(textInput, onError);

				if (!buffer) return;
				await props.onSubmitAnswer(props.partNumber, buffer);
			};

			return <div style={{ paddingTop: 15 }}>
				<GenericFileDragDropComponent onSubmit={handleSubmitAnswerAdventOfDesign} sectionStyle={{ paddingBottom: 15 }} />
			</div>;
		}
		default: return null;
	}
}
