import { useEffect, useState } from 'react';
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../config/initFirebase';

const useAuth = () => {
	const [user, setUser] = useState< User |null>(null);
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			setUser(user);
			setLoading(false);
		});
	}, []);

	return { user, isLoading };
};

export default useAuth;
