import { baseURL, auth } from 'config/initFirebase';
import AOCError from './AOCError';

type RequestMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export async function requestAPI(path: string, method: RequestMethods = 'GET', headers: HeadersInit = {}, body: BodyInit | null = null, config?: { followRedirects: boolean }) {
	try {
		const token = await auth.currentUser?.getIdToken();

		const options: RequestInit = {
			method,
			headers: { ...headers, ...(token && { Authorization: `${token}` }) },
			body,
		};

		if (config?.followRedirects) {
			options.redirect = 'follow';
		}

		const requestUrl = `${baseURL}${path}`;

		const response = await fetch(requestUrl, options);

		if (!response.ok) {
			const { message, errorCode, additionalData } = await response.json();

			throw new AOCError(message, errorCode, additionalData);
		}

		const isJsonResponse = (response.headers.get('content-type')?.indexOf('application/json') ?? -1) !== -1;

		if (isJsonResponse) {
			const jsonContent = await response.json();

			return jsonContent;
		}

		return response;
	} catch (e) {
		throw e;
	}
}
